import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from '@headlessui/react';
import { Header } from '../componetns/Header';
import { pokemonKind } from '../constants';
import { firstCapitalize } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';
import { Attack } from '../componetns/Attack';
import moveDetails from '../utils/move_power';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getItem, setItem } from '../utils/storage.utils';
import { css } from '../css_constants';
import { Button as MButton } from '@mui/material';

export default function AttackDrowpdown(props) {
	return (
		<div className="text-right">
			<Menu>
				<MenuButton className="h-7 inline-flex items-center gap-2 rounded-md bg-gray-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
					<Attack
						className="hover:bg-white/50"
						index={1}
						name={props.attack}
						onlyIcon={true}
						flip={true}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.attack].type}
						checked={props?.select == 1 ? true : false}
					/>
					<ChevronDownIcon className="size-4 fill-white/60" />
				</MenuButton>
				<Transition
					enter="transition ease-out duration-75"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<MenuItems
						anchor="bottom end"
						className="origin-top-right rounded-xl border border-white/5 bg-gray-800 p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none "
					>
						{props?.unlockedMoves?.map((move, index) => {
							return (
								<MenuItem>
									<button
										onClick={() => {
											props.onAttackSelect(move, index + 1);
										}}
										className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
									>
										<Attack
											onlyIcon={true}
											index={1}
											flip={true}
											name={move}
											isOpponent={true}
											onSelect={props?.onSelect}
											// type={moveDetails[attack.toLowerCase()].type}
											type={moveDetails[move].type}
											checked={props?.select == 1 ? true : false}
										/>

										<kbd className="ml-auto font-sans text-xs text-white/50 group-data-[focus]:inline">
											⌘
										</kbd>
									</button>
								</MenuItem>
							);
						})}
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	);
}

function PokemonItem(props) {
	return (
		<div
			className={`${css.bgLightColor}/90 w-[90%] py-2 rounded-md grid grid-cols-2 md:max-2xl:flex 2xl:flex items-center justify-evenly justify-items-center gap-2`}
		>
			<div className=" flex flex-col justify-center items-center">
				<img
					className="max-w-24 max-h-24 w-auto h-auto"
					src={`/images/front/${
						pokemonKind[props.pokemon.kind]
					}/${props.pokemon.name.toLowerCase()}.png`}
					// src="/abomasnow.png"
				/>
				<p
					className={`font-semibold text-sm ${css.textRedColr} ${
						!!props.disabled ? 'line-through' : ''
					}`}
				>
					{pokemonKind[props?.pokemon?.kind] == 'normal'
						? ''
						: firstCapitalize(pokemonKind[props?.pokemon?.kind])}{' '}
					{firstCapitalize(props?.pokemon?.name)}
				</p>
			</div>
			<div className="place-content-center">
				<div
					className={`flex flex-col justify-center items-center ${css.textRedColr}`}
				>
					<p className="text-xs">Level: {props?.pokemon?.level}</p>
					<p className="text-xs">Experience: {props?.pokemon?.exp}</p>
				</div>
				<div className="flex justify-center items-center text-xs font-bold">
					{getPokemonTypes(props?.pokemon?.type)}
				</div>
			</div>
			<div className="">
				<AttackDrowpdown
					attack={props?.pokemon?.attack1}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 1);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack2}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 2);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack3}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 3);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack4}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 4);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
			</div>
			{!props.select ? (
				<div
					className={`${
						props.hideButtons ? 'hidden' : ''
					} flex flex-col p-4 underline hover:cursor-pointer ${
						css.textDarkColor
					}`}
				>
					<Button
						onClick={() => {
							props.moveUp(props.id);
						}}
					>
						Move Up
					</Button>
					<Button>Replace</Button>
					<Button>Evolve</Button>
					<Button
						onClick={() => {
							props.moveDown(props.id);
						}}
					>
						Move Down
					</Button>
				</div>
			) : (
				<div
					className={`${
						props.hideButtons ? 'hidden' : ''
					} flex flex-col p-4 underline hover:cursor-pointer`}
				>
					<Button
						onClick={() => {
							props.onSelect(props?.pokemon);
						}}
					>
						Select
					</Button>
				</div>
			)}
		</div>
	);
}

export function ChangeTeam(props) {
	const [pokemons, setPokemons] = useState([] as any);
	const [maxPokemon, setMaxPokemon] = useState(0);
	const changeTeamPokemon = getItem('changeTeam');
	const [skip, setSkip] = useState(0);
	const navigate = useNavigate();

	const notifySaved = () => toast.success('Saved');
	const notifySavedFailure = () => toast.error('Failde to save');

	useEffect(() => {
		if (skip === 0 || skip < maxPokemon) {
			Request.getPokemonsExcludeTeam(skip)
				.then((res) => {
					res.data.pokemons.sort((a, b) => {
						return a.team_order - b.team_order;
					});
					setPokemons(res.data.pokemons);
					setMaxPokemon(res.data.count);
				})
				.catch(() => {});
		}
	}, [skip]);

	function moveUp(index) {
		if (index - 1 >= 0) {
			let temp = pokemons[index - 1];
			pokemons[index - 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index - 1].team_order = index - 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function moveDown(index) {
		if (index + 1 < pokemons.length) {
			let temp = pokemons[index + 1];
			pokemons[index + 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index + 1].team_order = index + 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function onAttackSelect(index, attack, attackId) {
		if (pokemons[index]) {
			pokemons[index][`attack${attackId}`] = attack;
			setPokemons([...pokemons]);
		}
	}

	function save() {
		Request.updateTeam(pokemons)
			.then(() => {
				notifySaved();
			})
			.catch(() => {
				notifySavedFailure();
			});
	}

	function onSelect(pokemon) {
		Request.replaceTeamPokemon(changeTeamPokemon, pokemon)
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setItem('changeTeam', '');
				navigate('/team', {
					replace: true,
				});
			});
	}

	return !changeTeamPokemon ? (
		<div>
			<p>Nothing here.</p>
		</div>
	) : (
		<div>
			<Header />
			<div className="flex flex-col justify-center">
				<h1
					className={`inline-block place-self-center p-2 m-1 mt-5 ${css.bgRedColor} ${css.textLightColor} rounded-md`}
				>
					Choose a replacement
				</h1>

				<div className="flex justify-center">
					<PokemonItem pokemon={changeTeamPokemon} hideButtons={true} />
				</div>
				<h1
					className={`inline-block place-self-center p-2 m-1 mt-5 ${css.bgRedColor} ${css.textLightColor} rounded-md`}
				>
					From
				</h1>
				<div
					className={`grid grid-rows-${pokemons.length} gap-4 place-items-center`}
				>
					{pokemons
						? pokemons.map((pokemon, index) => {
								return (
									<PokemonItem
										pokemon={pokemon}
										id={index}
										onAttackSelect={onAttackSelect}
										moveUp={moveUp}
										moveDown={moveDown}
										onSelect={onSelect}
										select={true}
									/>
								);
						  })
						: ''}
				</div>
			</div>
			<div className="flex justify-center gap-4 my-2">
				<MButton
					variant="contained"
					size="small"
					onClick={() => {
						setSkip(skip - 10 < 0 ? 0 : skip - 10);
					}}
					className={`${css.buttonRedStyle}`}
				>
					Prev
				</MButton>
				<MButton
					variant="contained"
					size="small"
					onClick={() => {
						setSkip(
							skip + 10 > maxPokemon ? skip + 10 - maxPokemon : skip + 10
						);
					}}
					className={`${css.buttonRedStyle}`}
				>
					Next
				</MButton>
			</div>
			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	);
}
