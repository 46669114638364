import { useNavigate } from 'react-router-dom';
import Request from '../utils/Request';
import { setItem } from '../utils/storage.utils';
import { useEffect, useState } from 'react';
import { Header } from '../componetns/Header';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import { css } from '../css_constants';
import { DialogBox } from '../componetns/Dialog';
import { toast, ToastContainer } from 'react-toastify';
import { firstCapitalize, imageSrc } from '../utils/utils';
import { ImSpinner2 } from 'react-icons/im';
import { LoadingImg } from '../componetns/LoadingImg';
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	leader: string;
	isDefeated?: boolean;
	onClick?: any;
	disableButton?: boolean;
	selectedGym?: string;
	canShowDefeated?: boolean;
}
function GymLeader(props: TabPanelProps) {
	const { children, index, leader, ...other } = props;
	return (
		<div
			role="tabpanel"
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			className={`m-4 p-2 relative cursor-pointer ${css.bgLightColor}`}
		>
			<Stack direction="column" alignItems={'center'} justifyContent={'center'}>
				<LoadingImg className="w-32" src={imageSrc(`leaders/${leader}.png`)} />
				<p className={`${css.textRedColr} my-2`}>{firstCapitalize(leader)}</p>
				<Button
					variant="contained"
					size="small"
					disabled={props.disableButton}
					className={`${css.bgRedColor} `}
					onClick={(event) => {
						event.preventDefault();
						if (!props.disableButton) {
							props.onClick(leader);
						}
					}}
				>
					{props?.selectedGym === leader ? 'Loading' : 'Battle'}
				</Button>
			</Stack>
			<h1
				hidden={!props?.isDefeated || props.canShowDefeated}
				className="text-2xl font-bold absolute left-[20%] top-1/4 opacity-70 text-green-600 -rotate-12"
			>
				Defeated
			</h1>
		</div>
	);
}
function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export function Gym(props) {
	const [value, setValue] = useState(0);
	const [selectedGym, setSelectedGym] = useState('');
	const navigate = useNavigate();
	const [gymData, setGymData] = useState(null as any);
	const [disableButtons, setDisableButtons] = useState(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const notifySavedFailure = (text) => toast.error(text);

	useEffect(() => {
		Request.gymRegionDetails()
			.then((res) => {
				setGymData(res.data);
			})
			.catch(() => {
				notifySavedFailure('Oops! not able to get gyms at this moment');
			});
	}, []);

	function onBattle(name: string) {
		if (typeof name === 'string') {
			setDisableButtons(true);
			setSelectedGym(name);
			Request.startGymBattle(name)
				.then((res) => {
					if (res.data.battleDetails.page) {
						setItem('battle', res.data.battleDetails);
						navigate('/battle', {
							replace: true,
						});
					}
				})
				.catch((error) => {
					notifySavedFailure('Failed to start battle');
				})
				.finally(() => {
					setDisableButtons(false);
					setSelectedGym('');
				});
		}
	}

	return gymData ? (
		<div>
			<Header />
			<div>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
						width: '80%',
						margin: 'auto',
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="Gym regions"
						scrollButtons={'auto'}
						variant="scrollable"
						centered
						sx={{
							'& .MuiTabs-indicator': {
								backgroundColor: css.lightColor,
							},
							'& .MuiButtonBase-root': {
								color: css.lightColor,
							},
							'& .Mui-selected': {
								color: css.lightColor,
							},
						}}
					>
						{gymData?.regions?.map((region, index) => {
							return <Tab label={region} {...a11yProps(index)} />;
						})}
					</Tabs>
				</Box>

				{gymData?.regions?.map((region, index) => {
					return (
						<div hidden={value !== index}>
							<Stack
								direction={'row'}
								justifyContent={'center'}
								flexWrap={'wrap'}
							>
								{gymData?.data?.[region]?.map((leader) => {
									return (
										<GymLeader
											isDefeated={gymData?.['defeated']?.find((value) => {
												return value === leader;
											})}
											canShowDefeated={!gymData}
											disableButton={disableButtons || value !== index}
											selectedGym={selectedGym}
											onClick={onBattle}
											index={index}
											leader={leader}
										/>
									);
								})}
							</Stack>
						</div>
					);
				})}
			</div>
			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	) : (
		<div>
			<Header />
			<div className="h-[400px] flex flex-col justify-center items-center">
				<ImSpinner2
					size={30}
					color={css.lightColor}
					className="animate-spin inline mr-1"
				/>
				<h1 className={`text-lg m-4 ${css.textLightColor}`}>
					Fetching gym details
				</h1>
			</div>
		</div>
	);
}
