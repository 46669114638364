import { Button } from '@mui/material';
import { BATTLE_TYPE } from '../constants';
import { css } from '../css_constants';
import { setItem } from '../utils/storage.utils';
import { firstCapitalize } from '../utils/utils';
import PokemonItem from './Pokemon';

export function LoseScreen(props) {
	setItem('battle', '');
	return (
		<div className="grid grid-cols-2 text-center mt-6 gap-5 ">
			<div className={`col-span-2 text-lg font-bold ${css.textLightColor}`}>
				Lost
			</div>
			<div className="col-span-2 flex flex-col justify-center gap-4 items-center">
				<p className={`${css.textLightColor} mx-5`}>
					You have been defeated by{' '}
					{props.state.battleType == BATTLE_TYPE.WILD_BATTLE
						? `Wild ${props.state.opponent[0].name} `
						: ''}
					{props.state.battleType == BATTLE_TYPE.USER &&
					props?.state?.userBattaleDetails?.name
						? firstCapitalize(props?.state?.userBattaleDetails?.name) +
						  "'s team. "
						: ''}
					{props.state.battleType == BATTLE_TYPE.GYM &&
					props?.state?.gymDetails?.name
						? firstCapitalize(props?.state?.gymDetails?.name) + "'s team. "
						: ''}
					{'. '}
				</p>
				<div className="flex justify-center items-center flex-wrap">
					{props.state.myState.map((poke) => {
						return <PokemonItem pokemon={poke} isOpponent={true} />;
					})}
				</div>
				<Button
					variant="contained"
					size="small"
					className={`${css.buttonRedStyle}`}
				>
					Battle Again?
				</Button>
			</div>
		</div>
	);
}
