import { FaHome } from 'react-icons/fa';
import { MdLogin } from 'react-icons/md';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FaQuestion } from 'react-icons/fa6';
import { FcAbout } from 'react-icons/fc';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { LiaMapSolid } from 'react-icons/lia';
import { CgProfile } from 'react-icons/cg';
import { MdCatchingPokemon } from 'react-icons/md';
import { GiCrossedSwords } from 'react-icons/gi';
import { RiLoopLeftLine } from 'react-icons/ri';
import { VscSymbolMisc } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION, PATH_MAPPING } from '../constants';
import Request from '../utils/Request';

const textColor = '#CE5A67';
const bgColor = 'bg-[#CE5A67]';
const subBgColor = 'bg-[#FF6969]';

function getMenuElement(id) {
	switch (id) {
		case 1:
			return <FaHome />;
		case 2:
			return <MdLogin />;
		case 3:
			return <AiOutlineUserAdd />;
		case 4:
			return <FaQuestion />;
		case 5:
			return <FcAbout />;
		case 6:
			return <LiaMapSolid />;
		case 7:
			return <CgProfile />;
		case 8:
			return <MdCatchingPokemon />;
		case 9:
			return <GiCrossedSwords />;
		case 10:
			return <RiLoopLeftLine />;
		case 11:
			return <VscSymbolMisc />;
	}
}

function Menu(props) {
	const [mouseHover, setmouseHover] = useState(false);

	function onMouseOver(event) {
		setmouseHover(true);
	}
	function onMouseOut(event) {
		setmouseHover(false);
	}

	return (
		<div
			id={props.icon}
			className={`${
				props.className ? props.className : ''
			} flex flex-col  hover:cursor-pointer hover:font-bold`}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			onClick={!mouseHover ? onMouseOver : onMouseOut}
		>
			<a
				className={`max-md:max-w-24 text-sm text-[#FFF5E0] px-2 py-1 max-md:mb-1 flex  rounded-sm shadow-sm   items-center ${bgColor} gap-1 justify-center`}
				onClick={() => {
					props.nav(props.name);
				}}
			>
				{getMenuElement(props.icon)}
				{props.name}

				<RiArrowDropDownLine
					className={`${props.isDropdown ? '' : 'hidden'}`}
				/>
			</a>
			<div
				className={`${
					mouseHover && props.dropdowns ? '' : 'hidden'
				} md:max-2xl:absolute 2xl:absolute md:max-2xl:mt-7 2xl:mt-7 mb-1`}
			>
				{props.dropdowns
					? props.dropdowns.map((value, index) => {
							return (
								<a
									id={index}
									className={`max-md:w-24 font-normal hover:underline ml-1 text-xs px-2 py-0.5 max-md:py-1  text-[#FFF5E0] rounded-sm mt-0.5 md:max-2xl:mt-1 ${bgColor} block top-[${
										index * 2
									}px] left-0}`}
									onClick={() => {
										props.nav(value);
									}}
								>
									{value}
								</a>
							);
					  })
					: ''}
			</div>
		</div>
	);
}

function Navigation() {
	const [toggle, setToggle] = useState(false);
	const [isLogin, setIsLogin] = useState(false);
	const navigate = useNavigate();

	const hideClass = `${isLogin ? 'hidden' : ''}`;
	const showClass = `${!isLogin ? 'hidden' : ''}`;

	useEffect(() => {
		if (localStorage.getItem('token')) {
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}
	}, []);

	function naivateTo(path) {
		navigate(PATH_MAPPING[path]);
	}

	function logout() {
		Request.logout()
			.catch(() => {})
			.finally(() => {
				navigate(PATH_MAPPING[NAVIGATION.LOGIN]);
			});
	}

	return (
		<div>
			<nav className="md:max-2xl:grid-cols-5 2xl:grid-cols-5 sm:max-md:relative grid-rows-1 h-6 gap-3 mt-4 mb-4">
				<GiHamburgerMenu
					className="md:max-2xl:hidden 2xl:hidden mt-4 ml-3 "
					color={textColor}
					size={30}
					onClick={() => {
						setToggle(!toggle);
					}}
				/>
				<div
					className={`md:max-2xl:flex ${
						!toggle ? 'max-md:hidden' : ''
					} 2xl:flex ${
						!toggle ? 'max-md:hidden' : ''
					} max-md:ml-3 gap-2 items-center justify-center`}
				>
					<Menu
						icon={1}
						className={hideClass}
						name={NAVIGATION.HOME}
						nav={naivateTo}
					/>
					<Menu
						icon={2}
						className={hideClass}
						name={NAVIGATION.LOGIN}
						nav={naivateTo}
					/>
					<Menu
						icon={3}
						className={hideClass}
						name={NAVIGATION.SIGNUP}
						nav={naivateTo}
					/>
					<Menu
						icon={4}
						className={hideClass}
						name={NAVIGATION.FAQ}
						nav={naivateTo}
					/>
					<Menu
						icon={5}
						className={hideClass}
						name={NAVIGATION.ABOUT}
						nav={naivateTo}
					/>

					<Menu
						icon={6}
						className={showClass}
						isDropdown={true}
						name={NAVIGATION.MAPS}
						nav={naivateTo}
					/>
					<Menu
						icon={7}
						className={showClass}
						isDropdown={true}
						name="Profile"
						nav={naivateTo}
						dropdowns={[NAVIGATION.YOUR_POKEMON, NAVIGATION.POKEMART]}
					/>
					<Menu
						icon={8}
						className={showClass}
						isDropdown={true}
						name="Pokemon"
						nav={naivateTo}
						dropdowns={[
							NAVIGATION.YOUR_TEAM,
							NAVIGATION.YOUR_POKEMON,
							NAVIGATION.POKEDEX,
							NAVIGATION.ATTACKDEX,
							NAVIGATION.TYPE_CHART,
						]}
					/>
					<Menu
						icon={9}
						className={showClass}
						isDropdown={true}
						name="Battle"
						nav={naivateTo}
						dropdowns={[
							NAVIGATION.GYM_BATTLE,
							NAVIGATION.USER_BATTLE,
							NAVIGATION.GOLD_BATTLES,
							NAVIGATION.EXP_BATTLES,
						]}
					/>
					<Menu
						icon={10}
						className={showClass}
						isDropdown={true}
						name="Trade"
						nav={naivateTo}
						dropdowns={[NAVIGATION.YOUR_TRADERS, NAVIGATION.YOUR_OFFERS]}
					/>
					<Menu
						icon={2}
						className={showClass}
						isDropdown={true}
						name="Logout"
						nav={logout}
					/>
				</div>
			</nav>
		</div>
	);
}

export default Navigation;
