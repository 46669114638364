import { LinearProgress } from '@mui/material';
import { pokemonKind } from '../constants';
import { css } from '../css_constants';
import moveDetails from '../utils/move_power';
import { firstCapitalize, getAttackList, nonNegative } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';
import { Attack } from './Attack';

interface PokemonItems {
	showTitle?: boolean;
	isOpponent?: boolean;
	index?: number;
	select?: number;
	onSelect?: Function;
	pokemon: any;
	showList?: any;
	disabled?: boolean;
	attackOnlyIcon?: boolean;
	attackClassName?: string;
	hideInput?: boolean;
	showDefeated?: boolean;
}

export default function PokemonItem(props: PokemonItems) {
	return (
		<div
			className={`flex flex-col rounded-md items-center justify-start bg-[#FFF5E0]/90 mx-4 my-2`}
		>
			{props.showTitle ? (
				<p className={`text-lg font-bold ${css.textRedColr} `}>
					{props.isOpponent ? 'Opponent' : 'Your Pokemon'}
				</p>
			) : (
				''
			)}
			<div
				className={`flex m-6 flex-wrap justify-center items-center  ${
					!!props.disabled ? 'grayscale' : ''
				} ${props.isOpponent ? '' : 'cursor-pointer'}`}
				onClick={() => {
					if (
						!props.disabled &&
						props?.onSelect &&
						!props.isOpponent &&
						!props?.showList
					) {
						props?.onSelect(props.pokemon.id);
					}
				}}
			>
				{props?.isOpponent ? (
					''
				) : props?.showList || props?.hideInput ? (
					''
				) : (
					<input
						type="radio"
						disabled={!!props.disabled}
						checked={props.select == props.pokemon.id ? true : false}
					/>
				)}
				<img
					className="max-w-24 max-h-24 w-auto h-auto"
					src={`/images/front/${
						pokemonKind[props.pokemon.kind]
					}/${props.pokemon.name.toLowerCase()}.png`}
					// src="/abomasnow.png"
				/>

				<div className="flex flex-col justify-center items-center ">
					<p
						className={`font-semibold text-sm ${css.textRedColr} ${
							!!props.disabled ? 'line-through graysclae' : ''
						}`}
					>
						{pokemonKind[props?.pokemon?.kind] == 'normal'
							? ''
							: firstCapitalize(pokemonKind[props?.pokemon?.kind])}{' '}
						{firstCapitalize(props?.pokemon?.name)}
					</p>
					<div className="flex justify-center items-center text-xs font-bold">
						{getPokemonTypes(props?.pokemon?.type)}
					</div>
					<LinearProgress
						variant={'buffer'}
						color="success"
						className="!rounded-full !h-2 !w-24"
						value={nonNegative(
							(props?.pokemon?.hp / props?.pokemon?.orginalHp) * 100,
							1
						)}
					/>
					<div className={`flex gap-1 font-semibold ${css.textRedColr}`}>
						<p className="text-[0.60rem]">LEVEL: {props?.pokemon?.level}</p>
						<p className="text-[0.60rem]">
							HP: {nonNegative(props?.pokemon?.hp)}
						</p>
					</div>
				</div>
				{props.showDefeated && props?.pokemon?.hp <= 0 ? (
					<h1 className="absolute text-3xl max-sm:text-2xl -rotate-12 opacity-75 font-bold text-red-500">
						DEFEATED
					</h1>
				) : (
					''
				)}
			</div>
			{props?.showList ? (
				<ul
					className={`flex flex-col items-start justify-center text-xs mb-2 ${css.textRedColr}`}
				>
					{getAttackList(props.pokemon).map((attack, index) => {
						if (attack) {
							return (
								<Attack
									index={index}
									name={attack}
									onlyIcon={props.attackOnlyIcon}
									className={props.attackClassName}
									isOpponent={props.isOpponent}
									onSelect={props.onSelect}
									type={moveDetails[attack.toLowerCase()].type}
									checked={props.select == index ? true : false}
								/>
							);
						} else {
							return '';
						}
					})}
				</ul>
			) : (
				''
			)}
		</div>
	);
}
