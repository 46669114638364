import { Header } from '../componetns/Header';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '../css_constants';
import { ImSpinner2 } from 'react-icons/im';
import { Button, Grid } from '@mui/material';
import { imageSrc } from '../utils/utils';

function Row(props) {
	return (
		<div
			className={`flex justify-evenly items-center text-center ${css.textLightColor}`}
		>
			<div className="flex flex-col justify-center items-center w-1/4">
				<img className="size-10" src={imageSrc(`mart/${props.name}.png`)} />
				<p className="text-sm">{props.items.name}</p>
				<p className="text-xs">{props.items.description}</p>
			</div>
			<input
				type="number"
				className="w-16 text-sm p-1 text-red-500"
				value={props.inputValue}
				placeholder="0"
				onChange={(event) => {
					props.onInput(props.name, event.target.value);
				}}
			/>
			<p className="text-sm">{props.items.bag}</p>
			<p className="text-sm">{props.items.price}</p>
		</div>
	);
}

export function PokeMart(props) {
	const [items, setItems] = useState({} as any);
	const [contentLoading, setContentLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [order, setOrder] = useState({});

	const notifySaved = (text) => toast.success(text);
	const notifySavedFailure = (text) => toast.error(text);

	useEffect(() => {
		Request.getPokeMartDetails()
			.then((res) => {
				setItems(res.data);
			})
			.catch(() => {})
			.finally(() => {
				setContentLoading(false);
			});
	}, []);

	function onInput(key, text) {
		if (key) {
			setOrder({
				...order,
				[key]: text,
			});
		}
	}

	function finalPrice() {
		let sum = 0;
		for (const key in order) {
			sum += order[key] * items?.items[key].price;
		}
		return sum;
	}

	async function onPurchase(price) {
		if (price <= items.coins) {
			setLoading(true);
			try {
				const res = await Request.purchase(order);
				let temp = { ...order };
				for (const key in temp) {
					temp[key] = '';
				}
				setOrder(temp);
				if (res.data.items) {
					setItems(res.data);
					notifySaved('Purchase successfull');
				} else {
					notifySavedFailure(res.data.message);
				}
			} catch (e) {
			} finally {
				setLoading(false);
			}
		} else {
			notifySavedFailure('Not enough coins!');
		}
	}

	return (
		<div>
			<Header />
			{contentLoading ? (
				<div className="h-[400px] flex flex-col justify-center items-center">
					<ImSpinner2
						size={30}
						color={css.lightColor}
						className="animate-spin inline mr-1"
					/>
					<h1 className={`text-lg m-4 ${css.textLightColor}`}>
						Fetching mart details
					</h1>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center">
					<h1
						className={` text-center inline-block place-self-center p-2 m-1 mt-5 ${css.bgRedColor} ${css.textLightColor} rounded-md`}
					>
						Market
					</h1>
					<h2 className={`text-center ${css.textLightColor} mt-6`}>
						You have <span className="text-green-400">{items?.coins}</span>{' '}
						coins to spend.
					</h2>

					<div className="flex flex-col gap-4 my-4">
						<div
							className={`flex h-4 justify-evenly items-center text-center ${css.textLightColor}`}
						>
							<p className="text-sm w-1/4">Item</p>
							<p className="text-sm w-16">Quantity</p>
							<p className="text-sm">In Bag</p>
							<p className="text-sm">Cost</p>
						</div>
						{items?.order?.map((key) => {
							if (items?.items[key].canShow) {
								return (
									<Row
										inputValue={order[key]}
										onInput={onInput}
										items={items?.items[key]}
										name={key}
									/>
								);
							}
						})}
					</div>
					<Button
						variant="contained"
						size="small"
						disabled={loading}
						className={`${css.bgRedColor} m-4 ${loading ? 'grayscale' : ''}`}
						onClick={(event) => {
							event.preventDefault();
							const price = finalPrice();
							if (price > 0) {
								onPurchase(price);
							} else {
								notifySavedFailure('Please add quantities');
							}
						}}
					>
						{loading
							? 'Purchasing please wait'
							: `Purchase for ${finalPrice()} coins`}
					</Button>
					<ToastContainer
						theme="colored"
						autoClose={2000}
						position="bottom-center"
					/>
				</div>
			)}
		</div>
	);
}
