import { css } from '../css_constants';
import { firstCapitalize } from './utils';

const POKEMON_COLOR_TYPES = {
	grass: 'bg-[#3eb322]',
	fire: 'bg-[#ff662e]',
	water: 'bg-[#2b99ff]',
	normal: 'bg-[#abacac]',
	electric: 'bg-[#ffdf00]',
	ice: 'bg-[##44dfff]',
	fighting: 'bg-[#ffa702]',
	poison: 'bg-[#9e4fd6]',
	ground: 'bg-[#b17d3b]',
	flying: 'bg-[#9cd3ff]',
	psychic: 'bg-[#ff6885]',
	bug: 'bg-[#a8ae26]',
	rock: 'bg-[#c0bc8c]',
	ghost: 'bg-[#734876]',
	dragon: 'bg-[#5867e1]',
	dark: 'bg-[#534b4b]',
	steel: 'bg-[#6fb7dd]',
	fairy: 'bg-[#ffb5ff]',
};

const POKEMON_COLOR_TYPES_ICONS = {
	grass: 'grass_icon.png',
	fire: 'fire_icon.png',
	water: 'water_icon.png',
	normal: 'normal_icon.png',
	electric: 'electric_icon.png',
	ice: 'ice_icon.png',
	fighting: 'fighting_icon.png',
	poison: 'poison_icon.png',
	ground: 'ground_icon.png',
	flying: 'flying_icon.png',
	psychic: 'psychic_icon.png',
	bug: 'bug_icon.png',
	rock: 'rock_icon.png',
	ghost: 'ghost_icon.png',
	dragon: 'dragon_icon.png',
	dark: 'dark_icon.png',
	steel: 'steel_icon.png',
	fairy: 'fairy_icon.png',
};

export function Test() {
	return <div></div>;
}

export function getPokemonTypes(type, onlyIcon = false, scale = 20) {
	return (
		<div className="flex justify-center items-center">
			{type.split(' ').map((t) => {
				return (
					<div
						className={`flex justify-start items-center ${
							POKEMON_COLOR_TYPES[t.toLowerCase()]
						} rounded-sm overflow-hidden m-0.5 p-0.5 max-h-4 max-sm:py-[0.5px] `}
					>
						<img
							className={`size-3 max-sm:size-2.5 max-h-4 max-w-4`}
							src={`/images/types/${
								POKEMON_COLOR_TYPES_ICONS[t.toLowerCase()]
							}`}
						/>
						{onlyIcon ? (
							''
						) : (
							<p className="text-[10px] max-sm:text-[7px]  ml-1 text-white">
								{t.toUpperCase()}
							</p>
						)}
					</div>
				);
			})}
		</div>
	);
}
