export function firstCapitalize(str: string = '') {
	return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function getAttackList(state) {
	return [state.attack1, state.attack2, state.attack3, state.attack4];
}

export function nonNegative(number, min = 0) {
	return number < 0 ? min : number;
}

export function imageSrc(path) {
	return 'images/' + path.toLowerCase();
}
