import { ImSpinner2 } from 'react-icons/im';
import { BATTLE_TYPE } from '../constants';
import PokemonItem from './Pokemon';
import { getItem } from '../utils/storage.utils';
import { css } from '../css_constants';
import { Button } from '@mui/material';

function damageMultiplierText(value) {
	if (value <= 1) {
		return ', had normal damage';
	} else if (value == 2) {
		return ' was super effective';
	} else if (value >= 4) {
		return ' was ultra effective';
	}
}

function createDamageText(damage: any, pokemon) {
	return `${
		damage.dodge
			? `${pokemon?.name?.toUpperCase() ?? 0} used ${
					damage?.attack?.toUpperCase() ?? 'attack'
			  } and was dodged`
			: `${pokemon?.name?.toUpperCase() ?? 0} used ${
					damage?.attack?.toUpperCase() ?? 'attack'
			  } ${damageMultiplierText(damage?.mult)} and did ${damage.damage} damage`
	}`;
}

export function PokemonBattle(props) {
	const profile = getItem('profile');
	return (
		<div className="grid grid-cols-2 text-center mt-6 gap-5">
			<div className={`col-span-2 text-lg font-bold ${css.textLightColor}`}>
				Battle
			</div>
			<div className="flex flex-col justify-center items-center">
				<PokemonItem
					showTitle={true}
					pokemon={props.you}
					onSelect={props.onSelect}
					select={props.select}
					hideInput={props.battleContinue}
					showList={!props.battleContinue}
					attackOnlyIcon={true}
					attackClassName={'my-0.5'}
					showDefeated={true}
				/>
				{props.dReceived ? (
					<p className={`${css.textLightColor} text-xs`}>
						{createDamageText(props.dReceived, props.opponent)}
					</p>
				) : (
					''
				)}
			</div>
			<div className="flex flex-col justify-center items-center">
				<PokemonItem
					showTitle={true}
					pokemon={props.opponent}
					showDefeated={true}
					isOpponent={true}
					showList={!props.battleContinue}
					attackOnlyIcon={true}
					attackClassName={'my-0.5'}
				/>
				{props.opponentDReceived ? (
					<p className={`${css.textLightColor} text-xs`}>
						{createDamageText(props.opponentDReceived, props.you)}
					</p>
				) : (
					''
				)}
			</div>
			<div className="col-span-2 place-self-center">
				<Button
					variant="contained"
					disabled={props.loading}
					onClick={(event) => {
						if (props.battleContinue) {
							props?.onContinue(event);
						} else {
							props?.onClick(event);
						}
					}}
					size="small"
					className={`${css.buttonRedStyle}`}
				>
					{props.loading ? (
						<ImSpinner2 className="animate-spin inline mr-1" />
					) : (
						''
					)}
					{props.loading
						? 'Please wait'
						: props.battleContinue
						? 'Continue'
						: 'Attack'}
				</Button>
			</div>
			{props.battleType == BATTLE_TYPE.WILD_BATTLE ? (
				<div className={`col-span-2 text-lg font-bold ${css.textLightColor}`}>
					Pokeballs
				</div>
			) : (
				''
			)}

			{props.battleType == BATTLE_TYPE.WILD_BATTLE ? (
				<div className="col-span-2 flex gap-3 items-center justify-center place-self-center m-4 text-blue-100">
					<div
						className={`flex justify-center flex-wrap gap-4 ${css.textLightColor}`}
					>
						<p
							onClick={() => {
								props?.onSelect('nball');
							}}
							className=""
						>
							<input
								className="mx-2"
								type="radio"
								checked={props.select == 'nball' ? true : false}
							/>
							Poke Ball x <span>{profile.items.nball}</span>
						</p>
						<p
							onClick={() => {
								props?.onSelect('uball');
							}}
						>
							<input
								className="mx-2"
								type="radio"
								checked={props.select == 'uball' ? true : false}
							/>
							Utlra ball x <span>{profile.items.uball}</span>
						</p>
						<p
							onClick={() => {
								props?.onSelect('gball');
							}}
						>
							<input
								className="mx-2"
								type="radio"
								checked={props.select == 'gball' ? true : false}
							/>
							Great ball x <span>{profile.items.gball}</span>
						</p>
						<p
							onClick={() => {
								props?.onSelect('mball');
							}}
						>
							<input
								className="mx-2"
								type="radio"
								checked={props.select == 'mball' ? true : false}
							/>
							Master ball x <span>{profile.items.mball}</span>
						</p>
					</div>
					<Button
						onClick={props.usePokemonBall}
						size="small"
						className="!bg-[#CE5A67] !text-[#FFF5E0] !px-2"
					>
						Use Ball
					</Button>
				</div>
			) : (
				''
			)}
		</div>
	);
}
