import { Button, Dialog, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { firstCapitalize, imageSrc } from '../utils/utils';
import { css } from '../css_constants';

type DialogButtons = {
	onClick?: any;
	text?: string;
};

type DialogProps = {
	onClose?: Function;
	buttons?: DialogButtons[];
	id?: string;
	pokemon?: any;
};

export function EvolveDailog(props: DialogProps) {
	let [isOpen, setIsOpen] = useState(true);

	useEffect(() => {
		open();
	});

	function open() {
		setIsOpen(true);
	}

	function close() {
		setIsOpen(false);
	}
	console.log(props.pokemon);
	return (
		<Dialog
			onClose={close}
			open={isOpen}
			sx={{
				'& .MuiPaper-root': {
					backgroundColor: css.lightColor,
				},
			}}
		>
			<DialogTitle>Evolve</DialogTitle>
			<div className="px-4 mb-2">
				<p className="pl-2">
					Do you want to evolve{' '}
					<span className={css.textRedColr}>
						{firstCapitalize(props.pokemon.name)}
					</span>{' '}
					into{' '}
					<span className={css.textRedColr}>
						{firstCapitalize(props.pokemon.nextEvoPokemon)}
					</span>
				</p>
				<div className="flex justify-center items-center">
					<img src={imageSrc(`front/normal/${props.pokemon.name}.png`)} />
					<p>evoles into</p>
					<img
						src={imageSrc(`front/normal/${props.pokemon.nextEvoPokemon}.png`)}
					/>
				</div>
				<div className="mb-4 flex items-center justify-evenly">
					{props?.buttons?.map((buttonDetail) => {
						return (
							<Button
								variant="contained"
								size="small"
								className={`${css.textLightColor} ${css.bgRedColor} mx-2`}
								onClick={(event) => {
									event.preventDefault();
									if (buttonDetail?.onClick) {
										close();
										buttonDetail?.onClick();
									}
								}}
							>
								{buttonDetail.text}
							</Button>
						);
					})}
				</div>
			</div>
		</Dialog>
	);
}
