import { Button } from '@mui/material';
import { css } from '../css_constants';
import { setItem } from '../utils/storage.utils';
import PokemonItem from './Pokemon';

export function CaughtScreen(props) {
	setItem('battle', '');
	return (
		<div
			className={`grid grid-cols-2 text-center mt-6 gap-5 ${css.textLightColor}`}
		>
			<div className={`col-span-2 text-lg font-bold ${css.textLightColor}`}>
				Battle
			</div>
			<div className="col-span-2 flex flex-col justify-center gap-4 items-center">
				<p className={`${css.textLightColor}`}>
					Congratulations! You have caught{' '}
					{props.state.battleType == 'wb'
						? `Wild ${props.state.opponent[0].name.toUpperCase()}`
						: ''}{' '}
				</p>
				<div className="flex justify-center items-center">
					{props.state.opponent.map((poke) => {
						return <PokemonItem pokemon={poke} isOpponent={true} />;
					})}
				</div>
				<Button
					variant="contained"
					onClick={props.goToMaps}
					size="small"
					className={`${css.buttonRedStyle}`}
				>
					Return to maps
				</Button>
			</div>
		</div>
	);
}
