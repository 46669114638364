import { useNavigate } from 'react-router-dom';
import Request from '../utils/Request';
import { setItem } from '../utils/storage.utils';
import { useEffect, useState } from 'react';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { ALL_POKEMON_TYPES } from '../constants';
import { firstCapitalize } from '../utils/utils';
import { Header } from '../componetns/Header';
import { css } from '../css_constants';

export function ExpGym(props) {
	const navigate = useNavigate();
	const [type, setType] = useState('');
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {});

	const handleChange = (event: SelectChangeEvent) => {
		setType(event.target.value as string);
	};

	function onStart(event) {
		event.preventDefault();
		setDisabled(true);
		if (type !== '') {
			Request.startTrainingBattle(type)
				.then((mapData) => {
					if (mapData.data.battleDetails.page) {
						setItem('battle', mapData.data.battleDetails);
						navigate('/battle', {
							replace: true,
						});
					}
				})
				.catch(() => {})
				.finally(() => {
					setDisabled(false);
				});
		}
	}

	return (
		<div>
			<Header />
			<div className="h-[400px] flex flex-col justify-center items-center">
				<FormControl
					className={`w-32 ${css.bgRedColor}`}
					color="success"
					variant="filled"
					sx={{
						'& .MuiFormLabel-root': {
							color: css.lightColor,
						},
						'& .MuiSelect-select': {
							color: css.lightColor,
						},
						'& .MuiSvgIcon-root': {
							color: css.lightColor,
						},
					}}
				>
					<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={type}
						label="Select Type"
						onChange={handleChange}
					>
						{ALL_POKEMON_TYPES.map((type) => {
							return <MenuItem value={type}>{firstCapitalize(type)}</MenuItem>;
						})}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					onClick={onStart}
					color="error"
					size="small"
					disabled={disabled}
					className={`${css.bgRedColor} mt-5`}
				>
					Start Training
				</Button>
			</div>
		</div>
	);
}
