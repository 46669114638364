import { useNavigate } from 'react-router-dom';
import Request from '../utils/Request';
import { setItem } from '../utils/storage.utils';
import { useEffect } from 'react';

export function UserSearch(props) {
	const navigate = useNavigate();
	useEffect(() => {
		Request.startUserBattle(1)
			.then((mapData) => {
				if (mapData.data.battleDetails.page) {
					setItem('battle', mapData.data.battleDetails);
					navigate('/battle', {
						replace: true,
					});
				}
			})
			.catch(() => {})
			.finally(() => {});
	});

	return <></>;
}
