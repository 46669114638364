export function setItem(key: string, value: any) {
	if (typeof value == 'object') {
		value = JSON.stringify(value);
	}
	localStorage.setItem(key, value);
}

export function getItem(key: string) {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (e) {
		return localStorage.getItem(key);
	}
}
