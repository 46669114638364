import axios from 'axios';
import { useEffect, useState } from 'react';
import Request, { SIGNUP_URL } from '../utils/Request';
import { useLocation, useNavigate } from 'react-router-dom';
import { BATTLE_TYPE, pokemonKind } from '../constants';
import { Header } from '../componetns/Header';
import { firstCapitalize, getAttackList } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';
import moveDetails from '../utils/move_power';
import { getItem, setItem } from '../utils/storage.utils';
import { ImSpinner2 } from 'react-icons/im';
import PokemonItem from '../componetns/Pokemon';
import { WinScreen } from '../componetns/WinScreen';
import { CaughtScreen } from '../componetns/Caught';
import { PokemonSelect } from '../componetns/PokemonSelect';
import { PokemonBattle } from '../componetns/PokemonBattle';
import { LoseScreen } from '../componetns/LoseScreen';
import { toast, ToastContainer } from 'react-toastify';

let waitOnSubmit = false;

export default function Battle(props) {
	const navigate = useNavigate();
	const location = useLocation();

	const [state, setState] = useState({} as any);
	const [select, setSelect] = useState(
		state?.page == 'selection' ? state.selectedPokemonId : null
	);
	const [attackSelect, setAttackSelect] = useState(0);
	const [loading, setLoading] = useState(false);

	const notifySavedFailure = (text) => toast.error(text);

	useEffect(() => {
		if (!Request.isTokenValid()) {
			navigate('/Login', { replace: true });
		} else {
			if (getItem('battle')) {
				const battle = getItem('battle');
				setState(battle);
			} else {
				navigate('/Home', { replace: true });
			}
		}
	}, []);

	async function onSelect(name) {
		setSelect(name);
	}

	async function onAttackSelect(name) {
		setAttackSelect(name);
	}

	async function handlePokemonSelect(event) {
		event.preventDefault();
		if (select != undefined && select != null) {
			if (!waitOnSubmit) {
				waitOnSubmit = true;
				try {
					const res = await Request.selectBattlePokemon(select);
					for (let i = 0; i < state.myState.length; i++) {
						const pokemon = state.myState[i];
						if (pokemon.id == state.selectedPokemonId && pokemon.hp <= 0) {
							setSelect(null);
						}
					}
					setSelect(null);
					setState(res.data.battleDetails);
					setItem('battle', res.data.battleDetails);
				} catch (error) {
					if (error.code == 'ERR_BAD_REQUEST') {
						setItem('battle', '');
						navigate('/Home', { replace: true });
					}
				} finally {
					waitOnSubmit = false;
				}
			}
		}
	}

	async function onContinue(event) {
		event.preventDefault();
		if (!loading) {
			setLoading(true);
			Request.battleContinue()
				.then((res) => {
					setItem('battle', res.data.battleDetails);
					setState(res.data.battleDetails);
				})
				.catch((error) => {
					notifySavedFailure(error.message);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}

	async function handlePokemonAttack(event) {
		event.preventDefault();
		if (attackSelect != undefined && attackSelect != null) {
			if (!waitOnSubmit) {
				waitOnSubmit = true;
				try {
					setLoading(true);
					const res = await Request.selectAttack((attackSelect ?? 0) + 1);
					let changeSelection = false;
					for (let i = 0; i < res.data.battleDetails.myState.length; i++) {
						const pokemon = res.data.battleDetails.myState[i];
						if (pokemon.id == res.data.battleDetails.selectedPokemonId) {
							if (pokemon.hp <= 0) {
								changeSelection = true;
							} else {
								setSelect(pokemon.id);
							}
							break;
						}
					}
					setItem('battle', res.data.battleDetails);
					if (res.data.battleDetails.profile) {
						setItem('profile', res.data.battleDetails.profile);
					}
					setState(res.data.battleDetails);
					if (changeSelection) {
						for (let i = 0; i < res.data.battleDetails.myState.length; i++) {
							const pokemon = res.data.battleDetails.myState[i];
							if (pokemon.hp > 0) {
								setSelect(pokemon.id);
								break;
							}
						}
					}
				} catch (error) {
					if (error.code == 'ERR_BAD_REQUEST') {
						navigate('/Home', { replace: true });
					}
					notifySavedFailure(error.message);
				} finally {
					setLoading(false);
					waitOnSubmit = false;
				}
			}
		}
	}

	async function usePokemonBall(event) {
		event.preventDefault();
		if (attackSelect != undefined && attackSelect != null) {
			if (!waitOnSubmit) {
				waitOnSubmit = true;
				const temp = attackSelect as any;
				try {
					setAttackSelect(null);
					const res = await Request.catch(attackSelect);
					if (res?.data?.battleDetails) {
						setState(res.data.battleDetails);
						setItem('battle', res.data.battleDetails);
						if (res.data.battleDetails.profile) {
							setItem('profile', res.data.battleDetails.profile);
						}
					}
					if (res?.data?.battleDetails?.error) {
						notifySavedFailure(res?.data?.battleDetails?.error);
					}
				} catch (error) {
					if (error.message == 'Not caught') {
						notifySavedFailure(error.message);
					}
					if (error.code == 'ERR_BAD_REQUEST') {
						navigate('/Home', { replace: true });
					}
				}
			}
		}
		waitOnSubmit = false;
	}

	async function goToMaps(event) {
		event.preventDefault();
		navigate('/map', { replace: true });
	}

	function onBattleEndContinue(event, navi) {
		setItem('battle', '');
		event.preventDefault();
		if (navi === 'again') {
			if (BATTLE_TYPE.GYM === state.battleType) {
				Request.startGymBattle(state.gymId)
					.then((res) => {
						if (res.data.battleDetails.page) {
							setItem('battle', res.data.battleDetails);
							setState(res.data.battleDetails);
						}
					})
					.catch((err) => {
						notifySavedFailure(err.message);
					})
					.finally(() => {});
			} else if (BATTLE_TYPE.USER === state.battleType) {
				Request.startUserBattle(state.userBattaleDetails.name)
					.then((res) => {
						if (res.data.battleDetails.page) {
							setItem('battle', res.data.battleDetails);
							setState(res.data.battleDetails);
						}
					})
					.catch((err) => {
						notifySavedFailure(err.message);
					})
					.finally(() => {});
			}
		} else if (navi === 'gym') {
			navigate('/gymbattle', { replace: true });
		} else if (navi === 'maps') {
			navigate('/map', { replace: true });
		}
	}

	return (
		<div>
			<Header />
			{state?.page == 'selection' ? (
				<PokemonSelect
					state={state}
					select={select}
					onSelect={onSelect}
					onClick={handlePokemonSelect}
				/>
			) : (
				''
			)}
			{state?.page == 'battle' || state.page == 'roundResults' ? (
				<PokemonBattle
					usePokemonBall={usePokemonBall}
					dReceived={state.dReceived}
					opponentDReceived={state.opponentDReceived}
					you={state.myState.find((poke) => {
						return state.selectedPokemonId == poke.id;
					})}
					opponent={state.opponent.find((poke) => {
						return state.opponentSelectedId == poke.id;
					})}
					battleContinue={state.page == 'roundResults'}
					select={attackSelect}
					onContinue={onContinue}
					onSelect={onAttackSelect}
					onClick={handlePokemonAttack}
					loading={loading}
					setLoading={setLoading}
					battleType={state.battleType}
				/>
			) : (
				''
			)}

			{state?.page == 'win' ? (
				<WinScreen state={state} onClick={onBattleEndContinue} />
			) : (
				''
			)}
			{state?.page == 'lose' ? (
				<LoseScreen state={state} onClick={onBattleEndContinue} />
			) : (
				''
			)}

			{state?.page == 'caught' ? (
				<CaughtScreen state={state} goToMaps={goToMaps} />
			) : (
				''
			)}
			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	);
}
