import { useEffect, useState } from 'react';
import { ImSpinner2 } from 'react-icons/im';

export function LoadingImg(props) {
	let [isLoaded, setIsLoaded] = useState(false);
	return (
		<div>
			<ImSpinner2
				className={`${isLoaded ? 'hidden' : ''} animate-spin inline mr-1`}
			/>
			<img
				hidden={!isLoaded}
				{...props}
				onLoad={() => {
					setIsLoaded(true);
				}}
			/>
		</div>
	);
}
