import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from '@headlessui/react';
import { Header } from '../componetns/Header';
import { pokemonKind } from '../constants';
import { firstCapitalize } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';
import { Attack } from '../componetns/Attack';
import moveDetails from '../utils/move_power';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImSpinner2 } from 'react-icons/im';
import { setItem } from '../utils/storage.utils';
import { useNavigate } from 'react-router-dom';
import { DialogBox } from '../componetns/Dialog';
import { css } from '../css_constants';
import { Button as ButtonUI } from '@mui/material';
import { EvolveDailog } from '../componetns/EvolveDailog';

export default function AttackDrowpdown(props) {
	return (
		<div className="text-right">
			<Menu>
				<MenuButton className="my-[1px] ml-[0.5px] inline-flex items-center gap-2 rounded-sm bg-gray-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
					<Attack
						className="hover:bg-white/50"
						index={1}
						name={props.attack}
						onlyIcon={true}
						flip={true}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.attack].type}
						checked={props?.select == 1 ? true : false}
					/>
					<ChevronDownIcon className="size-4 fill-white/60" />
				</MenuButton>
				<Transition
					enter="transition ease-out duration-75"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<MenuItems
						anchor="bottom end"
						className="origin-top-right rounded-xl border border-white/5 bg-gray-800 p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none "
					>
						{props?.unlockedMoves?.map((move, index) => {
							return (
								<MenuItem>
									<button
										onClick={() => {
											props.onAttackSelect(move, index + 1);
										}}
										className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
									>
										<Attack
											onlyIcon={true}
											index={1}
											flip={true}
											name={move}
											showAccuracy={true}
											isOpponent={true}
											onSelect={props?.onSelect}
											// type={moveDetails[attack.toLowerCase()].type}
											type={moveDetails[move].type}
											checked={props?.select == 1 ? true : false}
										/>

										<kbd className="ml-auto font-sans text-xs text-white/50 group-data-[focus]:inline">
											⌘
										</kbd>
									</button>
								</MenuItem>
							);
						})}
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	);
}

function PokemonItem(props) {
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		if (props?.isChanged && showLoading) {
			setShowLoading(false);
		}
	});
	return (
		<div
			className={`${css.bgLightColor}/90 w-[90%] py-2 rounded-md grid grid-cols-2 md:max-2xl:flex 2xl:flex items-center justify-evenly justify-items-center gap-2`}
		>
			<div className=" flex flex-col justify-center items-center">
				<img
					className="max-w-24 max-h-24 w-auto h-auto"
					src={`/images/front/${
						pokemonKind[props.pokemon.kind]
					}/${props.pokemon.name.toLowerCase()}.png`}
					// src="/abomasnow.png"
				/>
				<p className="font-semibold text-sm">
					{pokemonKind[props?.pokemon?.kind] == 'normal'
						? ''
						: firstCapitalize(pokemonKind[props?.pokemon?.kind])}{' '}
					{firstCapitalize(props?.pokemon?.name || 'Trevenant')}
				</p>
			</div>
			<div className="place-content-center">
				<div className="flex flex-col justify-center items-center">
					<p className="text-xs">Level : {props?.pokemon?.level}</p>
					<p className="text-xs">Experience : {props?.pokemon?.exp}</p>
				</div>
				<div className="flex justify-center text-xs font-bold">
					{getPokemonTypes(props?.pokemon?.type)}
				</div>
			</div>
			<div className="">
				<AttackDrowpdown
					attack={props?.pokemon?.attack1}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 1);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack2}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 2);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack3}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 3);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
				<AttackDrowpdown
					attack={props?.pokemon?.attack4}
					onAttackSelect={(attack, id) => {
						props?.onAttackSelect(props.id, attack, 4);
					}}
					unlockedMoves={props?.pokemon?.unlockedMoves}
				/>
			</div>
			{!showLoading ? (
				<div className="flex flex-col p-4 underline hover:cursor-pointer">
					{!props?.pokemon?.in_team ? (
						<Button
							onClick={() => {
								props?.moveToTrade([props?.pokemon.id]);
							}}
						>
							Put for trade
						</Button>
					) : (
						''
					)}
					{props?.pokemon?.in_team ? (
						<Button
							onClick={() => {
								props?.replace(props.id);
							}}
						>
							Replace from team
						</Button>
					) : (
						''
					)}
					{props?.pokemon?.canEvolve ? (
						<Button
							onClick={() => {
								props?.onEvolve(props?.pokemon);
							}}
						>
							Evolve
						</Button>
					) : (
						''
					)}
					{props?.isChanged ? (
						showLoading ? (
							<ImSpinner2 className="animate-spin inline mr-1" />
						) : (
							<Button
								onClick={() => {
									setShowLoading(true);
									props?.save(props?.pokemon);
								}}
							>
								Save
							</Button>
						)
					) : (
						''
					)}
				</div>
			) : (
				''
			)}
		</div>
	);
}

export function Pokemons(props) {
	const [pokemons, setPokemons] = useState([] as Array<any>);
	const [skip, setSkip] = useState(0);
	const [isChanged, setIsChanged] = useState(-1);
	const [inProgress, setInProgress] = useState(false);
	const [evolveDialog, setEvolveDialog] = useState({} as any);
	const [maxPokemon, setMaxPokemon] = useState(0);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(0);
	const navigate = useNavigate();

	const notifySaved = (text?) => toast.success(text || 'Saved');
	const notifySavedFailure = (text?) => toast.error(text || 'Failde to save');

	useEffect(() => {
		if (skip === 0 || skip < maxPokemon) {
			Request.getPokemons(skip)
				.then((res) => {
					res.data.pokemons.sort((a, b) => {
						return a.team_order - b.team_order;
					});
					setPokemons(res.data.pokemons);
					setMaxPokemon(res.data.count);
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [skip, reload]);

	function moveUp(index) {
		if (index - 1 >= 0) {
			let temp = pokemons[index - 1];
			pokemons[index - 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index - 1].team_order = index - 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function moveDown(index) {
		if (index + 1 < pokemons.length) {
			let temp = pokemons[index + 1];
			pokemons[index + 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index + 1].team_order = index + 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function onAttackSelect(index, attack, attackId) {
		if (pokemons[index]) {
			pokemons[index][`attack${attackId}`] = attack;
			setPokemons([...pokemons]);
			setIsChanged(index);
		}
	}

	function replace(index) {
		if (pokemons[index]) {
			setItem('changeTeam', pokemons[index]);
			navigate('/changeTeam', {
				replace: true,
			});
		}
	}

	function save(pokemon) {
		Request.updateTeam([pokemon])
			.then(() => {
				notifySaved();
			})
			.catch(() => {
				notifySavedFailure();
			})
			.finally(() => {
				setIsChanged(-1);
			});
	}

	function evolve(pokemon) {
		Request.evolve(pokemon.id)
			.then((res) => {
				if (res.data.evolved) {
					const index = pokemons.findIndex(() => {
						return pokemon.id === res.data.evolved.id;
					});
					if (index >= 0) {
						pokemons[index] = res.data.evolved;
						setPokemons([...pokemons]);
						notifySaved(
							`${firstCapitalize(
								pokemon.name
							)} was evolved into ${firstCapitalize(pokemons[index].name)}`
						);
					}
				}
			})
			.catch(() => {
				notifySavedFailure();
			})
			.finally(() => {
				setInProgress(false);
			});
	}

	function onEvolve(pokemon) {
		setEvolveDialog(pokemon);
	}

	async function moveToTrade(pokemons) {
		try {
			setLoading(true);
			await Request.moveToTrade(pokemons);
			notifySaved('Moved to trade');
			setReload(reload + 1);
		} catch (error) {
			setLoading(false);
			notifySavedFailure('Could not move pokemon for trade');
			console.error(error);
		}
	}

	return pokemons && pokemons.length > 0 ? (
		<div>
			<Header />
			<div className="flex flex-col justify-center">
				<h1
					className={`col-span-3 place-self-center p-2 m-1 mt-5 ${css.LOGIN.titlebgColor} text-[#CE5A67] rounded-md`}
				>
					YOUR POKEMONS
				</h1>
				<div
					className={`grid grid-rows-${pokemons.length} gap-4 place-items-center`}
				>
					{pokemons
						? pokemons.map((pokemon, index) => {
								return (
									<PokemonItem
										moveToTrade={moveToTrade}
										pokemon={pokemon}
										id={index}
										onAttackSelect={onAttackSelect}
										moveUp={moveUp}
										isChanged={isChanged === index || inProgress}
										save={save}
										moveDown={moveDown}
										replace={replace}
										onEvolve={onEvolve}
									/>
								);
						  })
						: ''}
				</div>
			</div>
			<div className="flex justify-center items-center">
				<ButtonUI
					variant="contained"
					size="small"
					disabled={skip <= 0 || loading}
					className={`${css.bgRedColor} m-2 ${
						skip <= 0 || loading ? 'grayscale' : ''
					}`}
					onClick={(event) => {
						event.preventDefault();
						if (skip >= 10) {
							setLoading(true);
							setSkip(skip - 10);
						}
					}}
				>
					PREV
				</ButtonUI>
				<ButtonUI
					variant="contained"
					size="small"
					disabled={skip + 10 >= maxPokemon || loading}
					className={`${css.bgRedColor} m-2 ${
						skip + 10 >= maxPokemon || loading ? 'grayscale' : ''
					}`}
					onClick={(event) => {
						event.preventDefault();
						console.log(skip);
						if (skip + 10 <= maxPokemon) {
							setLoading(true);
							setSkip(skip + 10);
						}
					}}
				>
					NEXT
				</ButtonUI>
			</div>
			{evolveDialog && evolveDialog?.id ? (
				<EvolveDailog
					pokemon={evolveDialog}
					onClose={() => {
						setEvolveDialog({});
					}}
					buttons={[
						{
							text: 'Evolve',
							onClick: () => {
								evolve(evolveDialog);
								setInProgress(true);
								setEvolveDialog({});
							},
						},
						{
							text: 'Cancel',
							onClick: () => {
								setEvolveDialog({});
							},
						},
					]}
				/>
			) : (
				''
			)}
			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	) : (
		<div>
			<Header />
			<div className="h-[400px] flex flex-col justify-center items-center">
				<ImSpinner2
					size={30}
					color={css.lightColor}
					className="animate-spin inline mr-1"
				/>
				<h1 className={`text-lg m-4 ${css.textLightColor}`}>
					Fetching your pokemons
				</h1>
			</div>
		</div>
	);
}
