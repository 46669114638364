import axios from 'axios';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { useNavigate } from 'react-router-dom';
import { Header } from '../componetns/Header';
import { Button } from '@mui/material';
import { ImSpinner2 } from 'react-icons/im';
import { css } from '../css_constants';
import { getPokemonTypes } from '../utils/utils.component';
const buttonStype = `!bg-[#CE5A67] hover:!bg-[#E78895]`;
export default function Home() {
	const [user, setuser]: any = useState();
	const [pokemonCount, setPokemonCount] = useState();
	const [initalPokemons, setInitalPokemons] = useState();
	const [selectedPokemon, setSelectedPokemon] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (Request.isTokenValid()) {
			Request.userProfile()
				.then((user) => {
					setuser(user.data);
					setPokemonCount(user.data.pokemonCount);
					setInitalPokemons(user.data.initialPokemons);
				})
				.catch(() => {
					navigate('/login', { replace: true });
				});
		} else {
			navigate('/login', { replace: true });
		}
	}, []);

	async function handlePokemonSelect(event) {
		event.preventDefault();
		setShowLoading(true);
		try {
			const res = await Request.addPokemon(selectedPokemon);
			setPokemonCount(res.data.length);
		} catch (error) {
		} finally {
			setShowLoading(false);
		}
	}

	function onPokemonSelect(name) {
		setSelectedPokemon(name);
	}

	function showInitalPokemons() {
		const list = [];

		for (let i = 0; i < user?.initialPokemons.length; i++) {
			const name = user?.initialPokemons[i];

			list.push(
				<div
					className={`flex flex-col w-full justify-center items-center ${css.bgLightColor}`}
					onClick={() => {
						onPokemonSelect(name);
					}}
				>
					<img src={`/images/front/normal/${name.toLowerCase()}.png`} />
					<input
						type="radio"
						id={name}
						name={name}
						value={name}
						checked={selectedPokemon == name ? true : false}
					/>
					<label htmlFor={name}>{name}</label>
				</div>
			);
		}

		return list;
	}

	if (pokemonCount === 0) {
		return (
			<div>
				<Header />
				<h1 className="text-[#FFF5E1] font-bold text-center my-4 mx-4 mt-10">
					Welcome to PokeLeagueRPG
				</h1>
				<h2 className="text-[#FFF5E1] text-center my-4 mx-4 ">
					Please choose a inital pokemon for your jurney from the below
				</h2>
				<div
					className={`grid grid-cols-3 mx-10  max-sm:mx-4 rounded-sm place-items-center gap-2`}
				>
					<div className={`${css.bgLightColor} w-full flex justify-center`}>
						{getPokemonTypes('grass')}
					</div>
					<div className={`${css.bgLightColor} w-full flex justify-center`}>
						{getPokemonTypes('fire')}
					</div>
					<div className={`${css.bgLightColor} w-full flex justify-center`}>
						{getPokemonTypes('water')}
					</div>
					{showInitalPokemons()}
				</div>
				<div className="flex justify-center m-4">
					<Button
						disabled={showLoading}
						variant="contained"
						onClick={handlePokemonSelect}
						size="medium"
						className={`${false ? '!hidden ' : ''}${buttonStype}`}
					>
						{showLoading ? (
							<ImSpinner2 className="animate-spin inline mr-1" />
						) : (
							''
						)}
						Select pokemon
					</Button>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<Header />
				<h1 className="text-[#FFF5E1] text-center my-4  mt-10">
					Welcome back!
				</h1>
				<div className="text-center">
					<a className="text-[#FFF5E1] text-center my-4 mt-10" href="/map">
						Go to maps?
					</a>
				</div>
			</div>
		);
	}
}
