import moveDetails from '../utils/move_power';
import { firstCapitalize } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';

export function Attack(props) {
	return (
		<li
			className={`flex justify-center items-center hover:cursor-pointer ${
				props?.className ? props?.className : ''
			}`}
			onClick={() => {
				if (props.onSelect) {
					props.onSelect(props?.index);
				}
			}}
		>
			{props?.isOpponent ? '' : <input type="radio" checked={props.checked} />}

			{!props.flip ? (
				<p className="px-1 text-sm">{firstCapitalize(props.name)}</p>
			) : (
				''
			)}

			{getPokemonTypes(props?.type, props.onlyIcon)}
			{props.flip ? (
				<p className="px-1  max-sm:text-xs">
					{firstCapitalize(props.name)}{' '}
					<span className="text-xs">
						[
						{moveDetails[props.name]?.attack
							? moveDetails[props.name]?.attack
							: ''}
						]
					</span>
					<span> </span>
					{props?.showAccuracy ? (
						<span className="text-xs">
							[
							{moveDetails[props.name]?.accuracy
								? moveDetails[props.name]?.accuracy
								: ''}
							]
						</span>
					) : (
						''
					)}
				</p>
			) : (
				''
			)}
		</li>
	);
}
