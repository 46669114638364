const moveDetails = {
	'10,000,000 volt thunderbolt': {
		name: '10,000,000 volt thunderbolt',
		type: 'electric',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	absorb: {
		name: 'absorb',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	accelrock: {
		name: 'accelrock',
		type: 'rock',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	acid: {
		name: 'acid',
		type: 'poison',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'acid downpour': {
		name: 'acid downpour',
		type: 'poison',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	acrobatics: {
		name: 'acrobatics',
		type: 'flying',
		attack: 55,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aerial ace': {
		name: 'aerial ace',
		type: 'flying',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	aeroblast: {
		name: 'aeroblast',
		type: 'flying',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'air cutter': {
		name: 'air cutter',
		type: 'flying',
		attack: 60,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'air slash': {
		name: 'air slash',
		type: 'flying',
		attack: 75,
		accuracy: 95,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'all-out pummeling': {
		name: 'all-out pummeling',
		type: 'fighting',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'alluring voice': {
		name: 'alluring voice',
		type: 'fairy',
		attack: 80,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 40,
	},
	'anchor shot': {
		name: 'anchor shot',
		type: 'steel',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'ancient power': {
		name: 'ancient power',
		type: 'rock',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'apple acid': {
		name: 'apple acid',
		type: 'grass',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aqua cutter': {
		name: 'aqua cutter',
		type: 'water',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aqua jet': {
		name: 'aqua jet',
		type: 'water',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aqua step': {
		name: 'aqua step',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aqua tail': {
		name: 'aqua tail',
		type: 'water',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'arm thrust': {
		name: 'arm thrust',
		type: 'fighting',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'armor cannon': {
		name: 'armor cannon',
		type: 'fire',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	assurance: {
		name: 'assurance',
		type: 'dark',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	astonish: {
		name: 'astonish',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'astral barrage': {
		name: 'astral barrage',
		type: 'ghost',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'attack order': {
		name: 'attack order',
		type: 'bug',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aura sphere': {
		name: 'aura sphere',
		type: 'fighting',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aura wheel': {
		name: 'aura wheel',
		type: 'electric',
		attack: 110,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'aurora beam': {
		name: 'aurora beam',
		type: 'ice',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	avalanche: {
		name: 'avalanche',
		type: 'ice',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'axe kick': {
		name: 'axe kick',
		type: 'fighting',
		attack: 120,
		accuracy: 90,
		effect: 'confuse',
		effectAccuracy: 30,
	},
	'baddy bad': {
		name: 'baddy bad',
		type: 'dark',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'barb barrage': {
		name: 'barb barrage',
		type: 'poison',
		attack: 60,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	barrage: {
		name: 'barrage',
		type: 'normal',
		attack: 45,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'beak blast': {
		name: 'beak blast',
		type: 'flying',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'beat up': {
		name: 'beat up',
		type: 'dark',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'behemoth bash': {
		name: 'behemoth bash',
		type: 'steel',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'behemoth blade': {
		name: 'behemoth blade',
		type: 'steel',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	belch: {
		name: 'belch',
		type: 'poison',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	bind: {
		name: 'bind',
		type: 'normal',
		attack: 45,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	bite: {
		name: 'bite',
		type: 'dark',
		attack: 60,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'bitter blade': {
		name: 'bitter blade',
		type: 'fire',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bitter malice': {
		name: 'bitter malice',
		type: 'ghost',
		attack: 60,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 40,
	},
	'black hole eclipse': {
		name: 'black hole eclipse',
		type: 'dark',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'blast burn': {
		name: 'blast burn',
		type: 'fire',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'blaze kick': {
		name: 'blaze kick',
		type: 'fire',
		attack: 85,
		accuracy: 90,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'blazing torque': {
		name: 'blazing torque',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'bleakwind storm': {
		name: 'bleakwind storm',
		type: 'flying',
		attack: 95,
		accuracy: 80,
		effect: 'freeze',
		effectAccuracy: 40,
	},
	blizzard: {
		name: 'blizzard',
		type: 'ice',
		attack: 110,
		accuracy: 70,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'blood moon': {
		name: 'blood moon',
		type: 'normal',
		attack: 140,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bloom doom': {
		name: 'bloom doom',
		type: 'grass',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'blue flare': {
		name: 'blue flare',
		type: 'fire',
		attack: 130,
		accuracy: 85,
		effect: 'burn',
		effectAccuracy: 20,
	},
	'body press': {
		name: 'body press',
		type: 'fighting',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'body slam': {
		name: 'body slam',
		type: 'normal',
		attack: 85,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'bolt beak': {
		name: 'bolt beak',
		type: 'electric',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bolt strike': {
		name: 'bolt strike',
		type: 'electric',
		attack: 130,
		accuracy: 85,
		effect: 'paralyz',
		effectAccuracy: 20,
	},
	'bone club': {
		name: 'bone club',
		type: 'ground',
		attack: 65,
		accuracy: 85,
		effect: 'flinch',
		effectAccuracy: 10,
	},
	'bone rush': {
		name: 'bone rush',
		type: 'ground',
		attack: 65,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	bonemerang: {
		name: 'bonemerang',
		type: 'ground',
		attack: 50,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	boomburst: {
		name: 'boomburst',
		type: 'normal',
		attack: 140,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	bounce: {
		name: 'bounce',
		type: 'flying',
		attack: 85,
		accuracy: 85,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'bouncy bubble': {
		name: 'bouncy bubble',
		type: 'water',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'branch poke': {
		name: 'branch poke',
		type: 'grass',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'brave bird': {
		name: 'brave bird',
		type: 'flying',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'breaking swipe': {
		name: 'breaking swipe',
		type: 'dragon',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'breakneck blitz': {
		name: 'breakneck blitz',
		type: 'normal',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'brick break': {
		name: 'brick break',
		type: 'fighting',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	brine: {
		name: 'brine',
		type: 'water',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'brutal swing': {
		name: 'brutal swing',
		type: 'dark',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	bubble: {
		name: 'bubble',
		type: 'water',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bubble beam': {
		name: 'bubble beam',
		type: 'water',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bug bite': {
		name: 'bug bite',
		type: 'bug',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bug buzz': {
		name: 'bug buzz',
		type: 'bug',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	bulldoze: {
		name: 'bulldoze',
		type: 'ground',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bullet punch': {
		name: 'bullet punch',
		type: 'steel',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'bullet seed': {
		name: 'bullet seed',
		type: 'grass',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'burn up': {
		name: 'burn up',
		type: 'fire',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'burning jealousy': {
		name: 'burning jealousy',
		type: 'fire',
		attack: 70,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 40,
	},
	'buzzy buzz': {
		name: 'buzzy buzz',
		type: 'electric',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	catastropika: {
		name: 'catastropika',
		type: 'electric',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'ceaseless edge': {
		name: 'ceaseless edge',
		type: 'dark',
		attack: 80,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'charge beam': {
		name: 'charge beam',
		type: 'electric',
		attack: 50,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	chatter: {
		name: 'chatter',
		type: 'flying',
		attack: 65,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 0,
	},
	'chilling water': {
		name: 'chilling water',
		type: 'water',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'chip away': {
		name: 'chip away',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	chloroblast: {
		name: 'chloroblast',
		type: 'grass',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'circle throw': {
		name: 'circle throw',
		type: 'fighting',
		attack: 60,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	clamp: {
		name: 'clamp',
		type: 'water',
		attack: 35,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'clanging scales': {
		name: 'clanging scales',
		type: 'dragon',
		attack: 110,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'clangorous soulblaze': {
		name: 'clangorous soulblaze',
		type: 'dragon',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'close combat': {
		name: 'close combat',
		type: 'fighting',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'collision course': {
		name: 'collision course',
		type: 'fighting',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'combat torque': {
		name: 'combat torque',
		type: 'fighting',
		attack: 100,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'comet punch': {
		name: 'comet punch',
		type: 'normal',
		attack: 52,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'confuse ray': {
		name: 'confuse ray',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 100,
	},
	confusion: {
		name: 'confusion',
		type: 'psychic',
		attack: 50,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 10,
	},
	constrict: {
		name: 'constrict',
		type: 'normal',
		attack: 10,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'continental crush': {
		name: 'continental crush',
		type: 'rock',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'core enforcer': {
		name: 'core enforcer',
		type: 'dragon',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'corkscrew crash': {
		name: 'corkscrew crash',
		type: 'steel',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	crabhammer: {
		name: 'crabhammer',
		type: 'water',
		attack: 100,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'cross chop': {
		name: 'cross chop',
		type: 'fighting',
		attack: 100,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'cross poison': {
		name: 'cross poison',
		type: 'poison',
		attack: 70,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 10,
	},
	crunch: {
		name: 'crunch',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'crush claw': {
		name: 'crush claw',
		type: 'normal',
		attack: 75,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	cut: {
		name: 'cut',
		type: 'normal',
		attack: 50,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'dark pulse': {
		name: 'dark pulse',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 20,
	},
	'dark void': {
		name: 'dark void',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'darkest lariat': {
		name: 'darkest lariat',
		type: 'dark',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dazzling gleam': {
		name: 'dazzling gleam',
		type: 'fairy',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'devastating drake': {
		name: 'devastating drake',
		type: 'dragon',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'diamond storm': {
		name: 'diamond storm',
		type: 'rock',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	dig: {
		name: 'dig',
		type: 'ground',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dire claw': {
		name: 'dire claw',
		type: 'poison',
		attack: 60,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 65,
	},
	'disarming voice': {
		name: 'disarming voice',
		type: 'fairy',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	discharge: {
		name: 'discharge',
		type: 'electric',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	dive: {
		name: 'dive',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dizzy punch': {
		name: 'dizzy punch',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 20,
	},
	'doom desire': {
		name: 'doom desire',
		type: 'steel',
		attack: 140,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'double hit': {
		name: 'double hit',
		type: 'normal',
		attack: 55,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'double iron bash': {
		name: 'double iron bash',
		type: 'steel',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'double kick': {
		name: 'double kick',
		type: 'fighting',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'double shock': {
		name: 'double shock',
		type: 'electric',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'double slap': {
		name: 'double slap',
		type: 'normal',
		attack: 45,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'double-edge': {
		name: 'double-edge',
		type: 'normal',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'draco meteor': {
		name: 'draco meteor',
		type: 'dragon',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon ascent': {
		name: 'dragon ascent',
		type: 'flying',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon breath': {
		name: 'dragon breath',
		type: 'dragon',
		attack: 60,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'dragon claw': {
		name: 'dragon claw',
		type: 'dragon',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon darts': {
		name: 'dragon darts',
		type: 'dragon',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon energy': {
		name: 'dragon energy',
		type: 'dragon',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon hammer': {
		name: 'dragon hammer',
		type: 'dragon',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon pulse': {
		name: 'dragon pulse',
		type: 'dragon',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon rage': {
		name: 'dragon rage',
		type: 'dragon',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon rush': {
		name: 'dragon rush',
		type: 'dragon',
		attack: 100,
		accuracy: 75,
		effect: 'flinch',
		effectAccuracy: 20,
	},
	'dragon tail': {
		name: 'dragon tail',
		type: 'dragon',
		attack: 60,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'drain punch': {
		name: 'drain punch',
		type: 'fighting',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'draining kiss': {
		name: 'draining kiss',
		type: 'fairy',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'drill peck': {
		name: 'drill peck',
		type: 'flying',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'drill run': {
		name: 'drill run',
		type: 'ground',
		attack: 80,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'drum beating': {
		name: 'drum beating',
		type: 'grass',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dual chop': {
		name: 'dual chop',
		type: 'dragon',
		attack: 40,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'dual wingbeat': {
		name: 'dual wingbeat',
		type: 'flying',
		attack: 80,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'dynamax cannon': {
		name: 'dynamax cannon',
		type: 'dragon',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dynamic punch': {
		name: 'dynamic punch',
		type: 'fighting',
		attack: 100,
		accuracy: 50,
		effect: 'confuse',
		effectAccuracy: 100,
	},
	'earth power': {
		name: 'earth power',
		type: 'ground',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	earthquake: {
		name: 'earthquake',
		type: 'ground',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'echoed voice': {
		name: 'echoed voice',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'eerie spell': {
		name: 'eerie spell',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'egg bomb': {
		name: 'egg bomb',
		type: 'normal',
		attack: 100,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	'electro ball': {
		name: 'electro ball',
		type: 'electric',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'electro drift': {
		name: 'electro drift',
		type: 'electric',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'electro shot': {
		name: 'electro shot',
		type: 'electric',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	ember: {
		name: 'ember',
		type: 'fire',
		attack: 40,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'energy ball': {
		name: 'energy ball',
		type: 'grass',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	eruption: {
		name: 'eruption',
		type: 'fire',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'esper wing': {
		name: 'esper wing',
		type: 'psychic',
		attack: 80,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	eternabeam: {
		name: 'eternabeam',
		type: 'dragon',
		attack: 120,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'expanding force': {
		name: 'expanding force',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	explosion: {
		name: 'explosion',
		type: 'normal',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	extrasensory: {
		name: 'extrasensory',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 10,
	},
	'extreme evoboost': {
		name: 'extreme evoboost',
		type: 'normal',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'extreme speed': {
		name: 'extreme speed',
		type: 'normal',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	facade: {
		name: 'facade',
		type: 'normal',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fairy wind': {
		name: 'fairy wind',
		type: 'fairy',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fake out': {
		name: 'fake out',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 100,
	},
	'false surrender': {
		name: 'false surrender',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'feint attack': {
		name: 'feint attack',
		type: 'dark',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fell stinger': {
		name: 'fell stinger',
		type: 'bug',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fickle beam': {
		name: 'fickle beam',
		type: 'dragon',
		attack: 104,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fiery dance': {
		name: 'fiery dance',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fiery wrath': {
		name: 'fiery wrath',
		type: 'dark',
		attack: 90,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 50,
	},
	'final gambit': {
		name: 'final gambit',
		type: 'fighting',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fire blast': {
		name: 'fire blast',
		type: 'fire',
		attack: 110,
		accuracy: 85,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'fire fang': {
		name: 'fire fang',
		type: 'fire',
		attack: 65,
		accuracy: 95,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'fire lash': {
		name: 'fire lash',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fire punch': {
		name: 'fire punch',
		type: 'fire',
		attack: 75,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'fire spin': {
		name: 'fire spin',
		type: 'fire',
		attack: 70,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'first impression': {
		name: 'first impression',
		type: 'bug',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fishious rend': {
		name: 'fishious rend',
		type: 'water',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	fissure: {
		name: 'fissure',
		type: 'ground',
		attack: 100,
		accuracy: 30,
		effect: '',
		effectAccuracy: 0,
	},
	'flame burst': {
		name: 'flame burst',
		type: 'fire',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'flame charge': {
		name: 'flame charge',
		type: 'fire',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'flame wheel': {
		name: 'flame wheel',
		type: 'fire',
		attack: 60,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 10,
	},
	flamethrower: {
		name: 'flamethrower',
		type: 'fire',
		attack: 90,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'flare blitz': {
		name: 'flare blitz',
		type: 'fire',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'flash cannon': {
		name: 'flash cannon',
		type: 'steel',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	flatter: {
		name: 'flatter',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 100,
	},
	'fleur cannon': {
		name: 'fleur cannon',
		type: 'fairy',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'flip turn': {
		name: 'flip turn',
		type: 'water',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'floaty fall': {
		name: 'floaty fall',
		type: 'flying',
		attack: 90,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'flower trick': {
		name: 'flower trick',
		type: 'grass',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	fly: {
		name: 'fly',
		type: 'flying',
		attack: 90,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'flying press': {
		name: 'flying press',
		type: 'fighting',
		attack: 80,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'focus blast': {
		name: 'focus blast',
		type: 'fighting',
		attack: 120,
		accuracy: 70,
		effect: '',
		effectAccuracy: 0,
	},
	'focus punch': {
		name: 'focus punch',
		type: 'fighting',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'force palm': {
		name: 'force palm',
		type: 'fighting',
		attack: 60,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'foul play': {
		name: 'foul play',
		type: 'dark',
		attack: 95,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'freeze shock': {
		name: 'freeze shock',
		type: 'ice',
		attack: 140,
		accuracy: 90,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'freeze-dry': {
		name: 'freeze-dry',
		type: 'ice',
		attack: 70,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'freezing glare': {
		name: 'freezing glare',
		type: 'psychic',
		attack: 90,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 50,
	},
	'freezy frost': {
		name: 'freezy frost',
		type: 'ice',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'frenzy plant': {
		name: 'frenzy plant',
		type: 'grass',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'frost breath': {
		name: 'frost breath',
		type: 'ice',
		attack: 60,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'fury attack': {
		name: 'fury attack',
		type: 'normal',
		attack: 45,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'fury cutter': {
		name: 'fury cutter',
		type: 'bug',
		attack: 70,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'fury swipes': {
		name: 'fury swipes',
		type: 'normal',
		attack: 55,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'fusion bolt': {
		name: 'fusion bolt',
		type: 'electric',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'fusion flare': {
		name: 'fusion flare',
		type: 'fire',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'gear grind': {
		name: 'gear grind',
		type: 'steel',
		attack: 70,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'genesis supernova': {
		name: 'genesis supernova',
		type: 'psychic',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'giga drain': {
		name: 'giga drain',
		type: 'grass',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'giga impact': {
		name: 'giga impact',
		type: 'normal',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'gigaton hammer': {
		name: 'gigaton hammer',
		type: 'steel',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'gigavolt havoc': {
		name: 'gigavolt havoc',
		type: 'electric',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'glacial lance': {
		name: 'glacial lance',
		type: 'ice',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	glaciate: {
		name: 'glaciate',
		type: 'ice',
		attack: 65,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'glaive rush': {
		name: 'glaive rush',
		type: 'dragon',
		attack: 110,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	glare: {
		name: 'glare',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 100,
	},
	'glitzy glow': {
		name: 'glitzy glow',
		type: 'psychic',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'grass whistle': {
		name: 'grass whistle',
		type: 'grass',
		attack: 30,
		accuracy: 55,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'grassy glide': {
		name: 'grassy glide',
		type: 'grass',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'grav apple': {
		name: 'grav apple',
		type: 'grass',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'guardian of alola': {
		name: 'guardian of alola',
		type: 'fairy',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	guillotine: {
		name: 'guillotine',
		type: 'normal',
		attack: 90,
		accuracy: 30,
		effect: '',
		effectAccuracy: 0,
	},
	'gunk shot': {
		name: 'gunk shot',
		type: 'poison',
		attack: 120,
		accuracy: 80,
		effect: 'poison',
		effectAccuracy: 30,
	},
	gust: {
		name: 'gust',
		type: 'flying',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hammer arm': {
		name: 'hammer arm',
		type: 'fighting',
		attack: 100,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'hard press': {
		name: 'hard press',
		type: 'steel',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'head charge': {
		name: 'head charge',
		type: 'normal',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'head smash': {
		name: 'head smash',
		type: 'rock',
		attack: 150,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	headbutt: {
		name: 'headbutt',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'headlong rush': {
		name: 'headlong rush',
		type: 'ground',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'heart stamp': {
		name: 'heart stamp',
		type: 'psychic',
		attack: 60,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'heat crash': {
		name: 'heat crash',
		type: 'fire',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'heat wave': {
		name: 'heat wave',
		type: 'fire',
		attack: 95,
		accuracy: 90,
		effect: 'burn',
		effectAccuracy: 10,
	},
	'heavy slam': {
		name: 'heavy slam',
		type: 'steel',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	hex: {
		name: 'hex',
		type: 'ghost',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hidden power': {
		name: 'hidden power',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'high horsepower': {
		name: 'high horsepower',
		type: 'ground',
		attack: 95,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'high jump kick': {
		name: 'high jump kick',
		type: 'fighting',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'horn attack': {
		name: 'horn attack',
		type: 'normal',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'horn drill': {
		name: 'horn drill',
		type: 'normal',
		attack: 70,
		accuracy: 30,
		effect: '',
		effectAccuracy: 0,
	},
	'horn leech': {
		name: 'horn leech',
		type: 'grass',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	hurricane: {
		name: 'hurricane',
		type: 'flying',
		attack: 110,
		accuracy: 70,
		effect: 'confuse',
		effectAccuracy: 30,
	},
	'hydro cannon': {
		name: 'hydro cannon',
		type: 'water',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'hydro pump': {
		name: 'hydro pump',
		type: 'water',
		attack: 110,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'hydro steam': {
		name: 'hydro steam',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hydro vortex': {
		name: 'hydro vortex',
		type: 'water',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hyper beam': {
		name: 'hyper beam',
		type: 'normal',
		attack: 150,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	'hyper drill': {
		name: 'hyper drill',
		type: 'normal',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hyper fang': {
		name: 'hyper fang',
		type: 'normal',
		attack: 80,
		accuracy: 90,
		effect: 'flinch',
		effectAccuracy: 10,
	},
	'hyper voice': {
		name: 'hyper voice',
		type: 'normal',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hyperspace fury': {
		name: 'hyperspace fury',
		type: 'dark',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'hyperspace hole': {
		name: 'hyperspace hole',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	hypnosis: {
		name: 'hypnosis',
		type: 'psychic',
		attack: 30,
		accuracy: 70,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'ice ball': {
		name: 'ice ball',
		type: 'ice',
		attack: 30,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'ice beam': {
		name: 'ice beam',
		type: 'ice',
		attack: 90,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'ice burn': {
		name: 'ice burn',
		type: 'ice',
		attack: 140,
		accuracy: 90,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'ice fang': {
		name: 'ice fang',
		type: 'ice',
		attack: 65,
		accuracy: 95,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'ice hammer': {
		name: 'ice hammer',
		type: 'ice',
		attack: 100,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'ice punch': {
		name: 'ice punch',
		type: 'ice',
		attack: 75,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'ice shard': {
		name: 'ice shard',
		type: 'ice',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'ice spinner': {
		name: 'ice spinner',
		type: 'ice',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'icicle crash': {
		name: 'icicle crash',
		type: 'ice',
		attack: 85,
		accuracy: 90,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'icicle spear': {
		name: 'icicle spear',
		type: 'ice',
		attack: 55,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'icy wind': {
		name: 'icy wind',
		type: 'ice',
		attack: 55,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	incinerate: {
		name: 'incinerate',
		type: 'fire',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'infernal parade': {
		name: 'infernal parade',
		type: 'ghost',
		attack: 60,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 30,
	},
	inferno: {
		name: 'inferno',
		type: 'fire',
		attack: 100,
		accuracy: 50,
		effect: 'burn',
		effectAccuracy: 100,
	},
	'inferno overdrive': {
		name: 'inferno overdrive',
		type: 'fire',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	infestation: {
		name: 'infestation',
		type: 'bug',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'iron head': {
		name: 'iron head',
		type: 'steel',
		attack: 80,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'iron tail': {
		name: 'iron tail',
		type: 'steel',
		attack: 100,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	'ivy cudgel': {
		name: 'ivy cudgel',
		type: 'grass',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'jaw lock': {
		name: 'jaw lock',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'jet punch': {
		name: 'jet punch',
		type: 'water',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	judgment: {
		name: 'judgment',
		type: 'normal',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'jump kick': {
		name: 'jump kick',
		type: 'fighting',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'karate chop': {
		name: 'karate chop',
		type: 'fighting',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'knock off': {
		name: 'knock off',
		type: 'dark',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'kowtow cleave': {
		name: 'kowtow cleave',
		type: 'dark',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'lash out': {
		name: 'lash out',
		type: 'dark',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'last resort': {
		name: 'last resort',
		type: 'normal',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'last respects': {
		name: 'last respects',
		type: 'ghost',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'lava plume': {
		name: 'lava plume',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'leaf blade': {
		name: 'leaf blade',
		type: 'grass',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'leaf storm': {
		name: 'leaf storm',
		type: 'grass',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'leaf tornado': {
		name: 'leaf tornado',
		type: 'grass',
		attack: 65,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	leafage: {
		name: 'leafage',
		type: 'grass',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	"let's snuggle forever": {
		name: "let's snuggle forever",
		type: 'fairy',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	lick: {
		name: 'lick',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'light of ruin': {
		name: 'light of ruin',
		type: 'fairy',
		attack: 140,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'light that burns the sky': {
		name: 'light that burns the sky',
		type: 'psychic',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	liquidation: {
		name: 'liquidation',
		type: 'water',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'lovely kiss': {
		name: 'lovely kiss',
		type: 'normal',
		attack: 30,
		accuracy: 75,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'low kick': {
		name: 'low kick',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'lumina crash': {
		name: 'lumina crash',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	lunge: {
		name: 'lunge',
		type: 'bug',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'luster purge': {
		name: 'luster purge',
		type: 'psychic',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mach punch': {
		name: 'mach punch',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'magical leaf': {
		name: 'magical leaf',
		type: 'grass',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'magical torque': {
		name: 'magical torque',
		type: 'fairy',
		attack: 100,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 30,
	},
	'magma storm': {
		name: 'magma storm',
		type: 'fire',
		attack: 120,
		accuracy: 75,
		effect: 'burn',
		effectAccuracy: 50,
	},
	'magnet bomb': {
		name: 'magnet bomb',
		type: 'steel',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	magnitude: {
		name: 'magnitude',
		type: 'ground',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'make it rain': {
		name: 'make it rain',
		type: 'steel',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'malicious moonsault': {
		name: 'malicious moonsault',
		type: 'dark',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'malignant chain': {
		name: 'malignant chain',
		type: 'poison',
		attack: 100,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 50,
	},
	'matcha gotcha': {
		name: 'matcha gotcha',
		type: 'grass',
		attack: 80,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'mega drain': {
		name: 'mega drain',
		type: 'grass',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mega kick': {
		name: 'mega kick',
		type: 'normal',
		attack: 120,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	'mega punch': {
		name: 'mega punch',
		type: 'normal',
		attack: 80,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	megahorn: {
		name: 'megahorn',
		type: 'bug',
		attack: 130,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'menacing moonraze maelstrom': {
		name: 'menacing moonraze maelstrom',
		type: 'ghost',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'metal burst': {
		name: 'metal burst',
		type: 'steel',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'metal claw': {
		name: 'metal claw',
		type: 'steel',
		attack: 50,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'meteor assault': {
		name: 'meteor assault',
		type: 'fighting',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'meteor beam': {
		name: 'meteor beam',
		type: 'rock',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'meteor mash': {
		name: 'meteor mash',
		type: 'steel',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'mighty cleave': {
		name: 'mighty cleave',
		type: 'rock',
		attack: 95,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mind blown': {
		name: 'mind blown',
		type: 'fire',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mirror shot': {
		name: 'mirror shot',
		type: 'steel',
		attack: 65,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'mist ball': {
		name: 'mist ball',
		type: 'psychic',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'misty explosion': {
		name: 'misty explosion',
		type: 'fairy',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	moonblast: {
		name: 'moonblast',
		type: 'fairy',
		attack: 95,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'moongeist beam': {
		name: 'moongeist beam',
		type: 'ghost',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mortal spin': {
		name: 'mortal spin',
		type: 'poison',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mountain gale': {
		name: 'mountain gale',
		type: 'ice',
		attack: 90,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'mud bomb': {
		name: 'mud bomb',
		type: 'ground',
		attack: 65,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'mud shot': {
		name: 'mud shot',
		type: 'ground',
		attack: 55,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'mud-slap': {
		name: 'mud-slap',
		type: 'ground',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'muddy water': {
		name: 'muddy water',
		type: 'water',
		attack: 90,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'multi-attack': {
		name: 'multi-attack',
		type: 'normal',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mystical fire': {
		name: 'mystical fire',
		type: 'fire',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'mystical power': {
		name: 'mystical power',
		type: 'psychic',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	"nature's madness": {
		name: "nature's madness",
		type: 'fairy',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'needle arm': {
		name: 'needle arm',
		type: 'grass',
		attack: 60,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'never-ending nightmare': {
		name: 'never-ending nightmare',
		type: 'ghost',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'night daze': {
		name: 'night daze',
		type: 'dark',
		attack: 85,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'night shade': {
		name: 'night shade',
		type: 'ghost',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'night slash': {
		name: 'night slash',
		type: 'dark',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'noxious torque': {
		name: 'noxious torque',
		type: 'poison',
		attack: 100,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	nuzzle: {
		name: 'nuzzle',
		type: 'electric',
		attack: 30,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 100,
	},
	'oblivion wing': {
		name: 'oblivion wing',
		type: 'flying',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'oceanic operetta': {
		name: 'oceanic operetta',
		type: 'water',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	octazooka: {
		name: 'octazooka',
		type: 'water',
		attack: 65,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'ominous wind': {
		name: 'ominous wind',
		type: 'ghost',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'order up': {
		name: 'order up',
		type: 'dragon',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'origin pulse': {
		name: 'origin pulse',
		type: 'water',
		attack: 110,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	outrage: {
		name: 'outrage',
		type: 'dragon',
		attack: 140,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	overdrive: {
		name: 'overdrive',
		type: 'electric',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	overheat: {
		name: 'overheat',
		type: 'fire',
		attack: 130,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'parabolic charge': {
		name: 'parabolic charge',
		type: 'electric',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'pay day': {
		name: 'pay day',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	payback: {
		name: 'payback',
		type: 'dark',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	peck: {
		name: 'peck',
		type: 'flying',
		attack: 35,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'petal blizzard': {
		name: 'petal blizzard',
		type: 'grass',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'petal dance': {
		name: 'petal dance',
		type: 'grass',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'phantom force': {
		name: 'phantom force',
		type: 'ghost',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'photon geyser': {
		name: 'photon geyser',
		type: 'psychic',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'pika papow': {
		name: 'pika papow',
		type: 'electric',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'pin missile': {
		name: 'pin missile',
		type: 'bug',
		attack: 65,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'plasma fists': {
		name: 'plasma fists',
		type: 'electric',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'play rough': {
		name: 'play rough',
		type: 'fairy',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	pluck: {
		name: 'pluck',
		type: 'flying',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'poison fang': {
		name: 'poison fang',
		type: 'poison',
		attack: 50,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	'poison gas': {
		name: 'poison gas',
		type: 'poison',
		attack: 30,
		accuracy: 90,
		effect: 'poison',
		effectAccuracy: 100,
	},
	'poison jab': {
		name: 'poison jab',
		type: 'poison',
		attack: 80,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	'poison powder': {
		name: 'poison powder',
		type: 'poison',
		attack: 30,
		accuracy: 75,
		effect: 'poison',
		effectAccuracy: 100,
	},
	'poison sting': {
		name: 'poison sting',
		type: 'poison',
		attack: 15,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	'poison tail': {
		name: 'poison tail',
		type: 'poison',
		attack: 50,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 10,
	},
	'pollen puff': {
		name: 'pollen puff',
		type: 'bug',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	poltergeist: {
		name: 'poltergeist',
		type: 'ghost',
		attack: 100,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'population bomb': {
		name: 'population bomb',
		type: 'normal',
		attack: 60,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	pounce: {
		name: 'pounce',
		type: 'bug',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	pound: {
		name: 'pound',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'powder snow': {
		name: 'powder snow',
		type: 'ice',
		attack: 40,
		accuracy: 100,
		effect: 'freeze',
		effectAccuracy: 10,
	},
	'power gem': {
		name: 'power gem',
		type: 'rock',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'power whip': {
		name: 'power whip',
		type: 'grass',
		attack: 120,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'power-up punch': {
		name: 'power-up punch',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'precipice blades': {
		name: 'precipice blades',
		type: 'ground',
		attack: 130,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'prismatic laser': {
		name: 'prismatic laser',
		type: 'psychic',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	psybeam: {
		name: 'psybeam',
		type: 'psychic',
		attack: 65,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 10,
	},
	psyblade: {
		name: 'psyblade',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	psychic: {
		name: 'psychic',
		type: 'psychic',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'psychic fangs': {
		name: 'psychic fangs',
		type: 'psychic',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'psychic noise': {
		name: 'psychic noise',
		type: 'psychic',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'psycho boost': {
		name: 'psycho boost',
		type: 'psychic',
		attack: 140,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'psycho cut': {
		name: 'psycho cut',
		type: 'psychic',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'psyshield bash': {
		name: 'psyshield bash',
		type: 'psychic',
		attack: 70,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	psyshock: {
		name: 'psyshock',
		type: 'psychic',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	psystrike: {
		name: 'psystrike',
		type: 'psychic',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	psywave: {
		name: 'psywave',
		type: 'psychic',
		attack: 60,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'pulverizing pancake': {
		name: 'pulverizing pancake',
		type: 'normal',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	pursuit: {
		name: 'pursuit',
		type: 'dark',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'pyro ball': {
		name: 'pyro ball',
		type: 'fire',
		attack: 120,
		accuracy: 90,
		effect: 'burn',
		effectAccuracy: 50,
	},
	'quick attack': {
		name: 'quick attack',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	rage: {
		name: 'rage',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'rage fist': {
		name: 'rage fist',
		type: 'ghost',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'raging bull': {
		name: 'raging bull',
		type: 'normal',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'raging fury': {
		name: 'raging fury',
		type: 'fire',
		attack: 90,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'rapid spin': {
		name: 'rapid spin',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'razor leaf': {
		name: 'razor leaf',
		type: 'grass',
		attack: 55,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'razor shell': {
		name: 'razor shell',
		type: 'water',
		attack: 75,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'razor wind': {
		name: 'razor wind',
		type: 'normal',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'relic song': {
		name: 'relic song',
		type: 'normal',
		attack: 75,
		accuracy: 100,
		effect: 'sleep',
		effectAccuracy: 10,
	},
	retaliate: {
		name: 'retaliate',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'revelation dance': {
		name: 'revelation dance',
		type: 'normal',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	revenge: {
		name: 'revenge',
		type: 'fighting',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'rising voltage': {
		name: 'rising voltage',
		type: 'electric',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'roar of time': {
		name: 'roar of time',
		type: 'dragon',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'rock blast': {
		name: 'rock blast',
		type: 'rock',
		attack: 75,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'rock climb': {
		name: 'rock climb',
		type: 'normal',
		attack: 90,
		accuracy: 85,
		effect: 'confuse',
		effectAccuracy: 20,
	},
	'rock slide': {
		name: 'rock slide',
		type: 'rock',
		attack: 75,
		accuracy: 90,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'rock smash': {
		name: 'rock smash',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'rock throw': {
		name: 'rock throw',
		type: 'rock',
		attack: 50,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'rock tomb': {
		name: 'rock tomb',
		type: 'rock',
		attack: 60,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'rock wrecker': {
		name: 'rock wrecker',
		type: 'rock',
		attack: 150,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'rolling kick': {
		name: 'rolling kick',
		type: 'fighting',
		attack: 60,
		accuracy: 85,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	rollout: {
		name: 'rollout',
		type: 'rock',
		attack: 30,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	round: {
		name: 'round',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	ruination: {
		name: 'ruination',
		type: 'dark',
		attack: 60,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'sacred fire': {
		name: 'sacred fire',
		type: 'fire',
		attack: 100,
		accuracy: 95,
		effect: 'burn',
		effectAccuracy: 50,
	},
	'sacred sword': {
		name: 'sacred sword',
		type: 'fighting',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'salt cure': {
		name: 'salt cure',
		type: 'rock',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sand tomb': {
		name: 'sand tomb',
		type: 'ground',
		attack: 75,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'sandsear storm': {
		name: 'sandsear storm',
		type: 'ground',
		attack: 95,
		accuracy: 80,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'sappy seed': {
		name: 'sappy seed',
		type: 'grass',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'savage spin-out': {
		name: 'savage spin-out',
		type: 'bug',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	scald: {
		name: 'scald',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'scale shot': {
		name: 'scale shot',
		type: 'dragon',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'scorching sands': {
		name: 'scorching sands',
		type: 'ground',
		attack: 70,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 50,
	},
	scratch: {
		name: 'scratch',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'searing shot': {
		name: 'searing shot',
		type: 'fire',
		attack: 100,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 30,
	},
	'searing sunraze smash': {
		name: 'searing sunraze smash',
		type: 'steel',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'secret power': {
		name: 'secret power',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'secret sword': {
		name: 'secret sword',
		type: 'fighting',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'seed bomb': {
		name: 'seed bomb',
		type: 'grass',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'seed flare': {
		name: 'seed flare',
		type: 'grass',
		attack: 120,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'seismic toss': {
		name: 'seismic toss',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow ball': {
		name: 'shadow ball',
		type: 'ghost',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow bone': {
		name: 'shadow bone',
		type: 'ghost',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow claw': {
		name: 'shadow claw',
		type: 'ghost',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow force': {
		name: 'shadow force',
		type: 'ghost',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow punch': {
		name: 'shadow punch',
		type: 'ghost',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shadow sneak': {
		name: 'shadow sneak',
		type: 'ghost',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shattered psyche': {
		name: 'shattered psyche',
		type: 'psychic',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sheer cold': {
		name: 'sheer cold',
		type: 'ice',
		attack: 100,
		accuracy: 30,
		effect: '',
		effectAccuracy: 0,
	},
	'shell side arm': {
		name: 'shell side arm',
		type: 'poison',
		attack: 90,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 50,
	},
	'shell trap': {
		name: 'shell trap',
		type: 'fire',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'shock wave': {
		name: 'shock wave',
		type: 'electric',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'signal beam': {
		name: 'signal beam',
		type: 'bug',
		attack: 75,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 10,
	},
	'silver wind': {
		name: 'silver wind',
		type: 'bug',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	sing: {
		name: 'sing',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'sleep',
		effectAccuracy: 80,
	},
	'sinister arrow raid': {
		name: 'sinister arrow raid',
		type: 'ghost',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sizzly slide': {
		name: 'sizzly slide',
		type: 'fire',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	sketch: {
		name: 'sketch',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'skitter smack': {
		name: 'skitter smack',
		type: 'bug',
		attack: 70,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'skull bash': {
		name: 'skull bash',
		type: 'normal',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sky attack': {
		name: 'sky attack',
		type: 'flying',
		attack: 140,
		accuracy: 90,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'sky drop': {
		name: 'sky drop',
		type: 'flying',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sky uppercut': {
		name: 'sky uppercut',
		type: 'fighting',
		attack: 85,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	slam: {
		name: 'slam',
		type: 'normal',
		attack: 80,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	slash: {
		name: 'slash',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sleep powder': {
		name: 'sleep powder',
		type: 'grass',
		attack: 30,
		accuracy: 75,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	sludge: {
		name: 'sludge',
		type: 'poison',
		attack: 65,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	'sludge bomb': {
		name: 'sludge bomb',
		type: 'poison',
		attack: 90,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 30,
	},
	'sludge wave': {
		name: 'sludge wave',
		type: 'poison',
		attack: 95,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'smack down': {
		name: 'smack down',
		type: 'rock',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'smart strike': {
		name: 'smart strike',
		type: 'steel',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	smog: {
		name: 'smog',
		type: 'poison',
		attack: 30,
		accuracy: 70,
		effect: 'poison',
		effectAccuracy: 40,
	},
	'snap trap': {
		name: 'snap trap',
		type: 'grass',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	snarl: {
		name: 'snarl',
		type: 'dark',
		attack: 55,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'snipe shot': {
		name: 'snipe shot',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	snore: {
		name: 'snore',
		type: 'normal',
		attack: 50,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'solar beam': {
		name: 'solar beam',
		type: 'grass',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'solar blade': {
		name: 'solar blade',
		type: 'grass',
		attack: 125,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sonic boom': {
		name: 'sonic boom',
		type: 'normal',
		attack: 30,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'soul-stealing 7-star strike': {
		name: 'soul-stealing 7-star strike',
		type: 'ghost',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spacial rend': {
		name: 'spacial rend',
		type: 'dragon',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	spark: {
		name: 'spark',
		type: 'electric',
		attack: 65,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'sparkly swirl': {
		name: 'sparkly swirl',
		type: 'fairy',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spectral thief': {
		name: 'spectral thief',
		type: 'ghost',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spike cannon': {
		name: 'spike cannon',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spin out': {
		name: 'spin out',
		type: 'steel',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spirit break': {
		name: 'spirit break',
		type: 'fairy',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'spirit shackle': {
		name: 'spirit shackle',
		type: 'ghost',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	splash: {
		name: 'splash',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'splintered stormshards': {
		name: 'splintered stormshards',
		type: 'rock',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'splishy splash': {
		name: 'splishy splash',
		type: 'water',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	spore: {
		name: 'spore',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'springtide storm': {
		name: 'springtide storm',
		type: 'fairy',
		attack: 95,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'steam eruption': {
		name: 'steam eruption',
		type: 'water',
		attack: 110,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	steamroller: {
		name: 'steamroller',
		type: 'bug',
		attack: 65,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'steel beam': {
		name: 'steel beam',
		type: 'steel',
		attack: 120,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'steel roller': {
		name: 'steel roller',
		type: 'steel',
		attack: 130,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'steel wing': {
		name: 'steel wing',
		type: 'steel',
		attack: 70,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'stoked sparksurfer': {
		name: 'stoked sparksurfer',
		type: 'electric',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	stomp: {
		name: 'stomp',
		type: 'normal',
		attack: 65,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 30,
	},
	'stomping tantrum': {
		name: 'stomping tantrum',
		type: 'ground',
		attack: 75,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'stone axe': {
		name: 'stone axe',
		type: 'rock',
		attack: 65,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'stone edge': {
		name: 'stone edge',
		type: 'rock',
		attack: 100,
		accuracy: 80,
		effect: '',
		effectAccuracy: 0,
	},
	'stored power': {
		name: 'stored power',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'storm throw': {
		name: 'storm throw',
		type: 'fighting',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'strange stream': {
		name: 'strange stream',
		type: 'fairy',
		attack: 90,
		accuracy: 95,
		effect: 'confuse',
		effectAccuracy: 50,
	},
	strength: {
		name: 'strength',
		type: 'normal',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	struggle: {
		name: 'struggle',
		type: 'normal',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	strugglebug: {
		name: 'strugglebug',
		type: 'bug',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'stun spore': {
		name: 'stun spore',
		type: 'grass',
		attack: 30,
		accuracy: 75,
		effect: 'paralyz',
		effectAccuracy: 100,
	},
	submission: {
		name: 'submission',
		type: 'fighting',
		attack: 80,
		accuracy: 75,
		effect: '',
		effectAccuracy: 0,
	},
	'subzero slammer': {
		name: 'subzero slammer',
		type: 'ice',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sucker punch': {
		name: 'sucker punch',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sunsteel strike': {
		name: 'sunsteel strike',
		type: 'steel',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'super fang': {
		name: 'super fang',
		type: 'normal',
		attack: 75,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'supercell slam': {
		name: 'supercell slam',
		type: 'electric',
		attack: 100,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	superpower: {
		name: 'superpower',
		type: 'fighting',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	supersonic: {
		name: 'supersonic',
		type: 'normal',
		attack: 30,
		accuracy: 55,
		effect: 'confuse',
		effectAccuracy: 100,
	},
	'supersonic skystrike': {
		name: 'supersonic skystrike',
		type: 'flying',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	surf: {
		name: 'surf',
		type: 'water',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'surging strikes': {
		name: 'surging strikes',
		type: 'water',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'sweet kiss': {
		name: 'sweet kiss',
		type: 'fairy',
		attack: 30,
		accuracy: 75,
		effect: 'confuse',
		effectAccuracy: 100,
	},
	swift: {
		name: 'swift',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	synchronoise: {
		name: 'synchronoise',
		type: 'psychic',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'syrup bomb': {
		name: 'syrup bomb',
		type: 'grass',
		attack: 60,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'tachyon cutter': {
		name: 'tachyon cutter',
		type: 'steel',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	tackle: {
		name: 'tackle',
		type: 'normal',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'tail slap': {
		name: 'tail slap',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'take down': {
		name: 'take down',
		type: 'normal',
		attack: 90,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'techno blast': {
		name: 'techno blast',
		type: 'normal',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'tectonic rage': {
		name: 'tectonic rage',
		type: 'ground',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'temper flare': {
		name: 'temper flare',
		type: 'fire',
		attack: 95,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'tera blast': {
		name: 'tera blast',
		type: 'normal',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'tera starstorm': {
		name: 'tera starstorm',
		type: 'normal',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	thief: {
		name: 'thief',
		type: 'dark',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	thrash: {
		name: 'thrash',
		type: 'normal',
		attack: 110,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'throat chop': {
		name: 'throat chop',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	thunder: {
		name: 'thunder',
		type: 'electric',
		attack: 110,
		accuracy: 70,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'thunder fang': {
		name: 'thunder fang',
		type: 'electric',
		attack: 65,
		accuracy: 95,
		effect: 'paralyz',
		effectAccuracy: 10,
	},
	'thunder punch': {
		name: 'thunder punch',
		type: 'electric',
		attack: 75,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 10,
	},
	'thunder shock': {
		name: 'thunder shock',
		type: 'electric',
		attack: 40,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 10,
	},
	'thunder wave': {
		name: 'thunder wave',
		type: 'electric',
		attack: 30,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 100,
	},
	thunderbolt: {
		name: 'thunderbolt',
		type: 'electric',
		attack: 90,
		accuracy: 100,
		effect: 'paralyz',
		effectAccuracy: 10,
	},
	thunderclap: {
		name: 'thunderclap',
		type: 'electric',
		attack: 85,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'thunderous kick': {
		name: 'thunderous kick',
		type: 'fighting',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'torch song': {
		name: 'torch song',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	toxic: {
		name: 'toxic',
		type: 'poison',
		attack: 30,
		accuracy: 90,
		effect: 'poison',
		effectAccuracy: 100,
	},
	'toxic thread': {
		name: 'toxic thread',
		type: 'poison',
		attack: 30,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 100,
	},
	'trail blaze': {
		name: 'trail blaze',
		type: 'grass',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'tri attack': {
		name: 'tri attack',
		type: 'normal',
		attack: 80,
		accuracy: 100,
		effect: 'burn',
		effectAccuracy: 20,
	},
	'triple arrows': {
		name: 'triple arrows',
		type: 'fighting',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'triple axel': {
		name: 'triple axel',
		type: 'ice',
		attack: 90,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'triple dive': {
		name: 'triple dive',
		type: 'water',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'triple kick': {
		name: 'triple kick',
		type: 'fighting',
		attack: 40,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'trop kick': {
		name: 'trop kick',
		type: 'grass',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'twin beam': {
		name: 'twin beam',
		type: 'psychic',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	twineedle: {
		name: 'twineedle',
		type: 'bug',
		attack: 40,
		accuracy: 100,
		effect: 'poison',
		effectAccuracy: 20,
	},
	'twinkle tackle': {
		name: 'twinkle tackle',
		type: 'fairy',
		attack: 200,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	twister: {
		name: 'twister',
		type: 'dragon',
		attack: 40,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 20,
	},
	'upper hand': {
		name: 'upper hand',
		type: 'fighting',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	uproar: {
		name: 'uproar',
		type: 'normal',
		attack: 110,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'v-create': {
		name: 'v-create',
		type: 'fire',
		attack: 150,
		accuracy: 95,
		effect: '',
		effectAccuracy: 0,
	},
	'vacuum wave': {
		name: 'vacuum wave',
		type: 'fighting',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'veevee volley': {
		name: 'veevee volley',
		type: 'normal',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	venoshock: {
		name: 'venoshock',
		type: 'poison',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'vice grip': {
		name: 'vice grip',
		type: 'normal',
		attack: 55,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'vine whip': {
		name: 'vine whip',
		type: 'grass',
		attack: 45,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'vital throw': {
		name: 'vital throw',
		type: 'fighting',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'volt tackle': {
		name: 'volt tackle',
		type: 'electric',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'wake-up slap': {
		name: 'wake-up slap',
		type: 'fighting',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'water gun': {
		name: 'water gun',
		type: 'water',
		attack: 40,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'water pulse': {
		name: 'water pulse',
		type: 'water',
		attack: 60,
		accuracy: 100,
		effect: 'confuse',
		effectAccuracy: 20,
	},
	'water shuriken': {
		name: 'water shuriken',
		type: 'water',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'water spout': {
		name: 'water spout',
		type: 'water',
		attack: 150,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	waterfall: {
		name: 'waterfall',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect: 'flinch',
		effectAccuracy: 20,
	},
	'wave crash': {
		name: 'wave crash',
		type: 'water',
		attack: 70,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'weather ball': {
		name: 'weather ball',
		type: 'normal',
		attack: 50,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	whirlpool: {
		name: 'whirlpool',
		type: 'water',
		attack: 80,
		accuracy: 85,
		effect: '',
		effectAccuracy: 0,
	},
	'wicked blow': {
		name: 'wicked blow',
		type: 'dark',
		attack: 100,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'wicked torque': {
		name: 'wicked torque',
		type: 'dark',
		attack: 80,
		accuracy: 100,
		effect: 'sleep',
		effectAccuracy: 10,
	},
	'wild charge': {
		name: 'wild charge',
		type: 'electric',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'wildbolt storm': {
		name: 'wildbolt storm',
		type: 'electric',
		attack: 95,
		accuracy: 80,
		effect: 'paralyz',
		effectAccuracy: 30,
	},
	'will-o-wisp': {
		name: 'will-o-wisp',
		type: 'fire',
		attack: 30,
		accuracy: 85,
		effect: 'burn',
		effectAccuracy: 85,
	},
	'wing attack': {
		name: 'wing attack',
		type: 'flying',
		attack: 60,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'wood hammer': {
		name: 'wood hammer',
		type: 'grass',
		attack: 120,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	wrap: {
		name: 'wrap',
		type: 'normal',
		attack: 35,
		accuracy: 90,
		effect: '',
		effectAccuracy: 0,
	},
	'x-scissor': {
		name: 'x-scissor',
		type: 'bug',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	yawn: {
		name: 'yawn',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'sleep',
		effectAccuracy: 100,
	},
	'zap cannon': {
		name: 'zap cannon',
		type: 'electric',
		attack: 120,
		accuracy: 50,
		effect: 'paralyz',
		effectAccuracy: 100,
	},
	'zen headbutt': {
		name: 'zen headbutt',
		type: 'psychic',
		attack: 80,
		accuracy: 90,
		effect: 'flinch',
		effectAccuracy: 20,
	},
	'zing zap': {
		name: 'zing zap',
		type: 'electric',
		attack: 80,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'zippy zap': {
		name: 'zippy zap',
		type: 'electric',
		attack: 65,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	accelerock: {
		name: 'accelerock',
		type: 'rock',
		attack: 40,
		accuracy: 100,
		effect: 'user attacks first.',
		effectAccuracy: 0,
	},
	'acid armor': {
		name: 'acid armor',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's defense.",
		effectAccuracy: 0,
	},
	'acid spray': {
		name: 'acid spray',
		type: 'poison',
		attack: 40,
		accuracy: 100,
		effect: "sharply lowers opponent's special defense.",
		effectAccuracy: 100,
	},
	acupressure: {
		name: 'acupressure',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'sharply raises a random stat.',
		effectAccuracy: 0,
	},
	'after you': {
		name: 'after you',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'gives target priority in the next turn.',
		effectAccuracy: 0,
	},
	agility: {
		name: 'agility',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's speed.",
		effectAccuracy: 0,
	},
	'ally switch': {
		name: 'ally switch',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'user switches with opposite teammate.',
		effectAccuracy: 0,
	},
	amnesia: {
		name: 'amnesia',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's special defense.",
		effectAccuracy: 0,
	},
	'aqua ring': {
		name: 'aqua ring',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: 'restores a little hp each turn.',
		effectAccuracy: 0,
	},
	aromatherapy: {
		name: 'aromatherapy',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'cures all status problems in your party.',
		effectAccuracy: 0,
	},
	'aromatic mist': {
		name: 'aromatic mist',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'raises special defense of an ally.',
		effectAccuracy: 0,
	},
	assist: {
		name: 'assist',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user performs a move known by its allies at random.',
		effectAccuracy: 0,
	},
	attract: {
		name: 'attract',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "if opponent is the opposite gender, it's less likely to attack.",
		effectAccuracy: 0,
	},
	'aurora veil': {
		name: 'aurora veil',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'halves damage from physical and special attacks for five turns.',
		effectAccuracy: 0,
	},
	autotomize: {
		name: 'autotomize',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: 'reduces weight and sharply raises speed.',
		effectAccuracy: 0,
	},
	'baby-doll eyes': {
		name: 'baby-doll eyes',
		type: 'fairy',
		attack: 30,
		accuracy: 100,
		effect: "always goes first. lowers the target's attack.",
		effectAccuracy: 0,
	},
	'baneful bunker': {
		name: 'baneful bunker',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect: 'protects the user and poisons opponent on contact.',
		effectAccuracy: 0,
	},
	barrier: {
		name: 'barrier',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's defense.",
		effectAccuracy: 0,
	},
	'baton pass': {
		name: 'baton pass',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user switches out and gives stat changes to the incoming pokémon.',
		effectAccuracy: 0,
	},
	'belly drum': {
		name: 'belly drum',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user loses 50% of its max hp, but attack raises to maximum.',
		effectAccuracy: 0,
	},
	bestow: {
		name: 'bestow',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "gives the user's held item to the target.",
		effectAccuracy: 0,
	},
	bide: {
		name: 'bide',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user takes damage for two turns then strikes back double.',
		effectAccuracy: 0,
	},
	block: {
		name: 'block',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'opponent cannot flee or switch.',
		effectAccuracy: 0,
	},
	'bulk up': {
		name: 'bulk up',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and defense.",
		effectAccuracy: 0,
	},
	'burning bulwark': {
		name: 'burning bulwark',
		type: 'fire',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'calm mind': {
		name: 'calm mind',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "raises user's special attack and special defense.",
		effectAccuracy: 0,
	},
	camouflage: {
		name: 'camouflage',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "changes user's type according to the location.",
		effectAccuracy: 0,
	},
	captivate: {
		name: 'captivate',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's special attack if opposite gender.",
		effectAccuracy: 0,
	},
	celebrate: {
		name: 'celebrate',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'the pokémon congratulates you on your special day. no battle effect.',
		effectAccuracy: 0,
	},
	charge: {
		name: 'charge',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect:
			"raises user's special defense and next electric move's power increases.",
		effectAccuracy: 0,
	},
	charm: {
		name: 'charm',
		type: 'fairy',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's attack.",
		effectAccuracy: 0,
	},
	'chilly reception': {
		name: 'chilly reception',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'switches out and summons a snowstorm lasting 5 turns.',
		effectAccuracy: 0,
	},
	'clangorous soul': {
		name: 'clangorous soul',
		type: 'dragon',
		attack: 30,
		accuracy: 100,
		effect: "raises all user's stats but loses hp.",
		effectAccuracy: 100,
	},
	'clear smog': {
		name: 'clear smog',
		type: 'poison',
		attack: 50,
		accuracy: 80,
		effect: "removes all of the target's stat changes.",
		effectAccuracy: 0,
	},
	coaching: {
		name: 'coaching',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: 'boosts attack and defense of a teammate.',
		effectAccuracy: 0,
	},
	coil: {
		name: 'coil',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack, defense and accuracy.",
		effectAccuracy: 0,
	},
	comeuppance: {
		name: 'comeuppance',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect:
			'deals more damage to the opponent that last inflicted damage on it.',
		effectAccuracy: 0,
	},
	confide: {
		name: 'confide',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "lowers opponent's special attack.",
		effectAccuracy: 100,
	},
	conversion: {
		name: 'conversion',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "changes user's type to that of its first move.",
		effectAccuracy: 0,
	},
	'conversion 2': {
		name: 'conversion 2',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user changes type to become resistant to opponent's last move.",
		effectAccuracy: 0,
	},
	copycat: {
		name: 'copycat',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "copies opponent's last move.",
		effectAccuracy: 0,
	},
	'corrosive gas': {
		name: 'corrosive gas',
		type: 'poison',
		attack: 30,
		accuracy: 100,
		effect: "removes opponent's items.",
		effectAccuracy: 0,
	},
	'cosmic power': {
		name: 'cosmic power',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense and special defense.",
		effectAccuracy: 0,
	},
	'cotton guard': {
		name: 'cotton guard',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: "drastically raises user's defense.",
		effectAccuracy: 0,
	},
	'cotton spore': {
		name: 'cotton spore',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's speed.",
		effectAccuracy: 0,
	},
	counter: {
		name: 'counter',
		type: 'fighting',
		attack: 30,
		accuracy: 100,
		effect: 'when hit by a physical attack, user strikes back with 2x power.',
		effectAccuracy: 0,
	},
	'court change': {
		name: 'court change',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'swaps the effects on either side of the field.',
		effectAccuracy: 0,
	},
	covet: {
		name: 'covet',
		type: 'normal',
		attack: 60,
		accuracy: 100,
		effect: "opponent's item is stolen by the user.",
		effectAccuracy: 0,
	},
	'crafty shield': {
		name: 'crafty shield',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'protects the pokémon from status moves.',
		effectAccuracy: 0,
	},
	'crush grip': {
		name: 'crush grip',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'more powerful when opponent has higher hp.',
		effectAccuracy: 0,
	},
	curse: {
		name: 'curse',
		type: 'ghost',
		attack: 30,
		accuracy: 80,
		effect:
			'ghosts lose 50% of max hp and curse the opponent; non-ghosts raise attack, defense and lower speed.',
		effectAccuracy: 0,
	},
	decorate: {
		name: 'decorate',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises target's attack and special attack.",
		effectAccuracy: 100,
	},
	'defend order': {
		name: 'defend order',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense and special defense.",
		effectAccuracy: 0,
	},
	'defense curl': {
		name: 'defense curl',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense.",
		effectAccuracy: 0,
	},
	defog: {
		name: 'defog',
		type: 'flying',
		attack: 30,
		accuracy: 80,
		effect: "lowers opponent's evasiveness and clears fog.",
		effectAccuracy: 0,
	},
	'destiny bond': {
		name: 'destiny bond',
		type: 'ghost',
		attack: 30,
		accuracy: 80,
		effect: 'if the user faints, the opponent also faints.',
		effectAccuracy: 0,
	},
	detect: {
		name: 'detect',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: 'protects the user, but may fail if used consecutively.',
		effectAccuracy: 0,
	},
	disable: {
		name: 'disable',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "opponent can't use its last attack for a few turns.",
		effectAccuracy: 0,
	},
	doodle: {
		name: 'doodle',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect:
			'changes the abilities of the user and its teammates to that of the target.',
		effectAccuracy: 0,
	},
	'double team': {
		name: 'double team',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's evasiveness.",
		effectAccuracy: 0,
	},
	'dragon cheer': {
		name: 'dragon cheer',
		type: 'dragon',
		attack: 30,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'dragon dance': {
		name: 'dragon dance',
		type: 'dragon',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and speed.",
		effectAccuracy: 0,
	},
	'dream eater': {
		name: 'dream eater',
		type: 'psychic',
		attack: 100,
		accuracy: 100,
		effect: 'user recovers half the hp inflicted on a sleeping opponent.',
		effectAccuracy: 0,
	},
	'eerie impulse': {
		name: 'eerie impulse',
		type: 'electric',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's special attack.",
		effectAccuracy: 0,
	},
	'electric terrain': {
		name: 'electric terrain',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: 'prevents all pokémon from falling asleep for 5 turns.',
		effectAccuracy: 0,
	},
	electrify: {
		name: 'electrify',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: "changes the target's move to electric type.",
		effectAccuracy: 0,
	},
	electroweb: {
		name: 'electroweb',
		type: 'electric',
		attack: 55,
		accuracy: 95,
		effect: "lowers opponent's speed.",
		effectAccuracy: 100,
	},
	embargo: {
		name: 'embargo',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'opponent cannot use items.',
		effectAccuracy: 0,
	},
	encore: {
		name: 'encore',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'forces opponent to keep using its last move for 3 turns.',
		effectAccuracy: 0,
	},
	endeavor: {
		name: 'endeavor',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "reduces opponent's hp to same as user's.",
		effectAccuracy: 0,
	},
	endure: {
		name: 'endure',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'always left with at least 1 hp, but may fail if used consecutively.',
		effectAccuracy: 0,
	},
	entrainment: {
		name: 'entrainment',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "makes target's ability same as user's.",
		effectAccuracy: 0,
	},
	'fairy lock': {
		name: 'fairy lock',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'prevents fleeing in the next turn.',
		effectAccuracy: 0,
	},
	'fake tears': {
		name: 'fake tears',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's special defense.",
		effectAccuracy: 0,
	},
	'false swipe': {
		name: 'false swipe',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: 'always leaves opponent with at least 1 hp.',
		effectAccuracy: 0,
	},
	'feather dance': {
		name: 'feather dance',
		type: 'flying',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's attack.",
		effectAccuracy: 0,
	},
	feint: {
		name: 'feint',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'only hits if opponent uses protect or detect in the same turn.',
		effectAccuracy: 0,
	},
	'fillet away': {
		name: 'fillet away',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'lowers hp but sharply boosts attack, special attack, and speed.',
		effectAccuracy: 0,
	},
	'fire pledge': {
		name: 'fire pledge',
		type: 'fire',
		attack: 80,
		accuracy: 100,
		effect:
			'added effects appear if combined with grass pledge or water pledge.',
		effectAccuracy: 0,
	},
	flail: {
		name: 'flail',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "the lower the user's hp, the higher the power.",
		effectAccuracy: 0,
	},
	flash: {
		name: 'flash',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's accuracy.",
		effectAccuracy: 0,
	},
	fling: {
		name: 'fling',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'power depends on held item.',
		effectAccuracy: 0,
	},
	'floral healing': {
		name: 'floral healing',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect:
			"the user restores the target's hp by up to half of its max hp. it restores more hp when the terrain is grass.",
		effectAccuracy: 0,
	},
	'flower shield': {
		name: 'flower shield',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'sharply raises defense of all grass-type pokémon on the field.',
		effectAccuracy: 100,
	},
	'focus energy': {
		name: 'focus energy',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'increases critical hit ratio.',
		effectAccuracy: 0,
	},
	'follow me': {
		name: 'follow me',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'in double battle, the user takes all the attacks.',
		effectAccuracy: 0,
	},
	foresight: {
		name: 'foresight',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			"resets opponent's evasiveness, and allows normal- and fighting-type attacks to hit ghosts.",
		effectAccuracy: 0,
	},
	"forest's curse": {
		name: "forest's curse",
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: 'adds grass type to opponent.',
		effectAccuracy: 0,
	},
	frustration: {
		name: 'frustration',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'power decreases with higher friendship.',
		effectAccuracy: 0,
	},
	'future sight': {
		name: 'future sight',
		type: 'psychic',
		attack: 120,
		accuracy: 100,
		effect: 'damage occurs 2 turns later.',
		effectAccuracy: 0,
	},
	'g-max befuddle': {
		name: 'g-max befuddle',
		type: 'bug',
		attack: 30,
		accuracy: 100,
		effect:
			'butterfree-exclusive g-max move. poisons, paralyzes, or puts opponent to sleep.',
		effectAccuracy: 100,
	},
	'g-max cannonade': {
		name: 'g-max cannonade',
		type: 'water',
		attack: 30,
		accuracy: 100,
		effect:
			'blastoise-exclusive g-max move. damages non-water types for 4 turns.',
		effectAccuracy: 0,
	},
	'g-max centiferno': {
		name: 'g-max centiferno',
		type: 'fire',
		attack: 30,
		accuracy: 100,
		effect: 'centiskorch-exclusive g-max move. traps opponents for 4-5 turns.',
		effectAccuracy: 100,
	},
	'g-max chi strike': {
		name: 'g-max chi strike',
		type: 'fighting',
		attack: 30,
		accuracy: 100,
		effect: 'machamp-exclusive g-max move. increases critical hit ratio.',
		effectAccuracy: 0,
	},
	'g-max cuddle': {
		name: 'g-max cuddle',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'eevee-exclusive g-max move. infatuates opponents.',
		effectAccuracy: 100,
	},
	'g-max depletion': {
		name: 'g-max depletion',
		type: 'dragon',
		attack: 30,
		accuracy: 100,
		effect: "duraludon-exclusive g-max move. reduces opponent's pp.",
		effectAccuracy: 0,
	},
	'g-max drum solo': {
		name: 'g-max drum solo',
		type: 'grass',
		attack: 160,
		accuracy: 100,
		effect: "rillaboom-exclusive g-max move. ignores target's ability.",
		effectAccuracy: 0,
	},
	'g-max finale': {
		name: 'g-max finale',
		type: 'fairy',
		attack: 30,
		accuracy: 100,
		effect: "alcremie-exclusive g-max move. heals the user's team.",
		effectAccuracy: 0,
	},
	'g-max fireball': {
		name: 'g-max fireball',
		type: 'fire',
		attack: 160,
		accuracy: 100,
		effect: "cinderace-exclusive g-max move. ignores target's ability.",
		effectAccuracy: 0,
	},
	'g-max foam burst': {
		name: 'g-max foam burst',
		type: 'water',
		attack: 30,
		accuracy: 100,
		effect: "kingler-exclusive g-max move. harshly lowers opponents' speed.",
		effectAccuracy: 100,
	},
	'g-max gold rush': {
		name: 'g-max gold rush',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect:
			'meowth-exclusive g-max move. confuses opponents and earns more money.',
		effectAccuracy: 100,
	},
	'g-max gravitas': {
		name: 'g-max gravitas',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: 'orbeetle-exclusive g-max move. summons gravity for 5 turns.',
		effectAccuracy: 0,
	},
	'g-max hydrosnipe': {
		name: 'g-max hydrosnipe',
		type: 'water',
		attack: 160,
		accuracy: 100,
		effect: "inteleon-exclusive g-max move. ignores target's ability.",
		effectAccuracy: 0,
	},
	'g-max malodor': {
		name: 'g-max malodor',
		type: 'poison',
		attack: 30,
		accuracy: 100,
		effect: 'garbodor-exclusive g-max move. poisons opponents.',
		effectAccuracy: 100,
	},
	'g-max meltdown': {
		name: 'g-max meltdown',
		type: 'steel',
		attack: 30,
		accuracy: 100,
		effect:
			'melmetal-exclusive g-max move. prevents opponents using the same move twice in a row.',
		effectAccuracy: 0,
	},
	'g-max one blow': {
		name: 'g-max one blow',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect:
			'urshifu single-strike style-exclusive g-max move. strikes through max guard and protect.',
		effectAccuracy: 0,
	},
	'g-max rapid flow': {
		name: 'g-max rapid flow',
		type: 'water',
		attack: 30,
		accuracy: 100,
		effect:
			'urshifu rapid-strike style-exclusive g-max move. strikes through max guard and protect.',
		effectAccuracy: 0,
	},
	'g-max replenish': {
		name: 'g-max replenish',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'snorlax-exclusive g-max move. recycles berries.',
		effectAccuracy: 0,
	},
	'g-max resonance': {
		name: 'g-max resonance',
		type: 'ice',
		attack: 30,
		accuracy: 100,
		effect: 'lapras-exclusive g-max move. reduces damage for 5 turns.',
		effectAccuracy: 0,
	},
	'g-max sandblast': {
		name: 'g-max sandblast',
		type: 'ground',
		attack: 30,
		accuracy: 100,
		effect: 'sandaconda-exclusive g-max move. traps opponents for 4-5 turns.',
		effectAccuracy: 100,
	},
	'g-max smite': {
		name: 'g-max smite',
		type: 'fairy',
		attack: 30,
		accuracy: 100,
		effect: 'hatterene-exclusive g-max move. confuses opponents.',
		effectAccuracy: 100,
	},
	'g-max snooze': {
		name: 'g-max snooze',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'grimmsnarl-exclusive g-max move. makes opponents drowsy.',
		effectAccuracy: 0,
	},
	'g-max steelsurge': {
		name: 'g-max steelsurge',
		type: 'steel',
		attack: 30,
		accuracy: 100,
		effect: 'copperajah-exclusive g-max move. sets up spikes on the field.',
		effectAccuracy: 0,
	},
	'g-max stonesurge': {
		name: 'g-max stonesurge',
		type: 'water',
		attack: 30,
		accuracy: 100,
		effect: 'drednaw-exclusive g-max move. sets up stealth rock.',
		effectAccuracy: 0,
	},
	'g-max stun shock': {
		name: 'g-max stun shock',
		type: 'electric',
		attack: 30,
		accuracy: 100,
		effect: 'toxtricity-exclusive g-max move. poisons or paralyzes opponents.',
		effectAccuracy: 100,
	},
	'g-max sweetness': {
		name: 'g-max sweetness',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect:
			"appletun-exclusive g-max move. heals status conditions of user's team.",
		effectAccuracy: 0,
	},
	'g-max tartness': {
		name: 'g-max tartness',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: "flapple-exclusive g-max move. reduces opponents' evasiveness.",
		effectAccuracy: 100,
	},
	'g-max terror': {
		name: 'g-max terror',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect:
			'gengar-exclusive g-max move. prevents opponent from switching out.',
		effectAccuracy: 0,
	},
	'g-max vine lash': {
		name: 'g-max vine lash',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect:
			'venusaur-exclusive g-max move. damages non-grass types for 4 turns.',
		effectAccuracy: 0,
	},
	'g-max volcalith': {
		name: 'g-max volcalith',
		type: 'rock',
		attack: 30,
		accuracy: 100,
		effect: 'coalossal-exclusive g-max move. deals damage for 4 turns.',
		effectAccuracy: 100,
	},
	'g-max volt crash': {
		name: 'g-max volt crash',
		type: 'electric',
		attack: 30,
		accuracy: 100,
		effect: 'pikachu-exclusive g-max move. paralyzes opponents.',
		effectAccuracy: 100,
	},
	'g-max wildfire': {
		name: 'g-max wildfire',
		type: 'fire',
		attack: 30,
		accuracy: 100,
		effect:
			'charizard-exclusive g-max move. damages non-fire types for 4 turns.',
		effectAccuracy: 100,
	},
	'g-max wind rage': {
		name: 'g-max wind rage',
		type: 'flying',
		attack: 30,
		accuracy: 100,
		effect: 'corviknight-exclusive g-max move. removes battlefield hazards.',
		effectAccuracy: 0,
	},
	'gastro acid': {
		name: 'gastro acid',
		type: 'poison',
		attack: 30,
		accuracy: 100,
		effect: "cancels out the effect of the opponent's ability.",
		effectAccuracy: 0,
	},
	'gear up': {
		name: 'gear up',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect:
			'the user engages its gears to raise the attack and sp. atk stats of ally pokémon with the plus or minus ability.',
		effectAccuracy: 0,
	},
	geomancy: {
		name: 'geomancy',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect:
			"charges on first turn, sharply raises user's sp. attack, sp. defense and speed on the second.",
		effectAccuracy: 0,
	},
	'grass knot': {
		name: 'grass knot',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: 'the heavier the opponent, the stronger the attack.',
		effectAccuracy: 0,
	},
	'grass pledge': {
		name: 'grass pledge',
		type: 'grass',
		attack: 80,
		accuracy: 100,
		effect:
			'added effects appear if preceded by water pledge or succeeded by fire pledge.',
		effectAccuracy: 0,
	},
	'grassy terrain': {
		name: 'grassy terrain',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'restores a little hp of all pokémon for 5 turns.',
		effectAccuracy: 0,
	},
	gravity: {
		name: 'gravity',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect:
			'prevents moves like fly and bounce and the ability levitate for 5 turns.',
		effectAccuracy: 0,
	},
	growl: {
		name: 'growl',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's attack.",
		effectAccuracy: 0,
	},
	growth: {
		name: 'growth',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and special attack.",
		effectAccuracy: 0,
	},
	grudge: {
		name: 'grudge',
		type: 'ghost',
		attack: 30,
		accuracy: 80,
		effect:
			"if the users faints after using this move, the pp for the opponent's last move is depleted.",
		effectAccuracy: 0,
	},
	'guard split': {
		name: 'guard split',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'averages defense and special defense with the target.',
		effectAccuracy: 0,
	},
	'guard swap': {
		name: 'guard swap',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'user and opponent swap defense and special defense.',
		effectAccuracy: 0,
	},
	'gyro ball': {
		name: 'gyro ball',
		type: 'steel',
		attack: 30,
		accuracy: 100,
		effect: 'the slower the user, the stronger the attack.',
		effectAccuracy: 0,
	},
	hail: {
		name: 'hail',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'non-ice types are damaged for 5 turns.',
		effectAccuracy: 0,
	},
	'happy hour': {
		name: 'happy hour',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'doubles prize money from trainer battles.',
		effectAccuracy: 0,
	},
	harden: {
		name: 'harden',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense.",
		effectAccuracy: 0,
	},
	haze: {
		name: 'haze',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'resets all stat changes.',
		effectAccuracy: 0,
	},
	'heal bell': {
		name: 'heal bell',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "heals the user's party's status conditions.",
		effectAccuracy: 0,
	},
	'heal block': {
		name: 'heal block',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: 'prevents the opponent from restoring hp for 5 turns.',
		effectAccuracy: 0,
	},
	'heal order': {
		name: 'heal order',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers half its max hp.',
		effectAccuracy: 0,
	},
	'heal pulse': {
		name: 'heal pulse',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "restores half the target's max hp.",
		effectAccuracy: 0,
	},
	'healing wish': {
		name: 'healing wish',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'the user faints and the next pokémon released is fully healed.',
		effectAccuracy: 0,
	},
	'heart swap': {
		name: 'heart swap',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'stat changes are swapped with the opponent.',
		effectAccuracy: 0,
	},
	'helping hand': {
		name: 'helping hand',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "in double battles, boosts the power of the partner's move.",
		effectAccuracy: 0,
	},
	'hold back': {
		name: 'hold back',
		type: 'normal',
		attack: 40,
		accuracy: 100,
		effect: 'always leaves opponent with at least 1 hp.',
		effectAccuracy: 0,
	},
	'hold hands': {
		name: 'hold hands',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'makes the user and an ally very happy.',
		effectAccuracy: 0,
	},
	'hone claws': {
		name: 'hone claws',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and accuracy.",
		effectAccuracy: 0,
	},
	howl: {
		name: 'howl',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'raises attack of allies.',
		effectAccuracy: 0,
	},
	imprison: {
		name: 'imprison',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'opponent is unable to use moves that the user also knows.',
		effectAccuracy: 0,
	},
	ingrain: {
		name: 'ingrain',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'user restores hp each turn. user cannot escape/switch.',
		effectAccuracy: 0,
	},
	instruct: {
		name: 'instruct',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'allows an ally to use a move instead.',
		effectAccuracy: 0,
	},
	'ion deluge': {
		name: 'ion deluge',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: 'changes normal-type moves to electric-type.',
		effectAccuracy: 0,
	},
	'iron defense': {
		name: 'iron defense',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's defense.",
		effectAccuracy: 0,
	},
	'jungle healing': {
		name: 'jungle healing',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: "restores team's hp and cures status conditions.",
		effectAccuracy: 0,
	},
	kinesis: {
		name: 'kinesis',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "lowers opponent's accuracy.",
		effectAccuracy: 0,
	},
	"king's shield": {
		name: "king's shield",
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: "protects the user and lowers opponent's attack on contact.",
		effectAccuracy: 0,
	},
	"land's wrath": {
		name: "land's wrath",
		type: 'ground',
		attack: 90,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'laser focus': {
		name: 'laser focus',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user's next attack is guaranteed to result in a critical hit.",
		effectAccuracy: 0,
	},
	'leech life': {
		name: 'leech life',
		type: 'bug',
		attack: 80,
		accuracy: 100,
		effect: 'user recovers half the hp inflicted on opponent.',
		effectAccuracy: 0,
	},
	'leech seed': {
		name: 'leech seed',
		type: 'grass',
		attack: 30,
		accuracy: 90,
		effect: 'drains hp from opponent each turn.',
		effectAccuracy: 0,
	},
	leer: {
		name: 'leer',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's defense.",
		effectAccuracy: 100,
	},
	'life dew': {
		name: 'life dew',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: 'user and teammates recover hp.',
		effectAccuracy: 0,
	},
	'light screen': {
		name: 'light screen',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'halves damage from special attacks for 5 turns.',
		effectAccuracy: 0,
	},
	'lock-on': {
		name: 'lock-on',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user's next attack is guaranteed to hit.",
		effectAccuracy: 0,
	},
	'low sweep': {
		name: 'low sweep',
		type: 'fighting',
		attack: 65,
		accuracy: 100,
		effect: "lowers opponent's speed.",
		effectAccuracy: 100,
	},
	'lucky chant': {
		name: 'lucky chant',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'opponent cannot land critical hits for 5 turns.',
		effectAccuracy: 0,
	},
	'lunar blessing': {
		name: 'lunar blessing',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "heals user's status conditions and recovers hp.",
		effectAccuracy: 0,
	},
	'lunar dance': {
		name: 'lunar dance',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'the user faints but the next pokémon released is fully healed.',
		effectAccuracy: 0,
	},
	'magic coat': {
		name: 'magic coat',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'reflects moves that cause status conditions back to the attacker.',
		effectAccuracy: 0,
	},
	'magic powder': {
		name: 'magic powder',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: "changes target's type to psychic.",
		effectAccuracy: 0,
	},
	'magic room': {
		name: 'magic room',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'suppresses the effects of held items for five turns.',
		effectAccuracy: 0,
	},
	'magnet rise': {
		name: 'magnet rise',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: 'user becomes immune to ground-type moves for 5 turns.',
		effectAccuracy: 0,
	},
	'magnetic flux': {
		name: 'magnetic flux',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: 'raises defense and sp. defense of plus/minus pokémon.',
		effectAccuracy: 0,
	},
	'mat block': {
		name: 'mat block',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: 'protects teammates from damaging moves.',
		effectAccuracy: 0,
	},
	'max airstream': {
		name: 'max airstream',
		type: 'flying',
		attack: 30,
		accuracy: 80,
		effect: "flying type dynamax move. raises the team's speed.",
		effectAccuracy: 100,
	},
	'max darkness': {
		name: 'max darkness',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: "dark type dynamax move. lowers the target's special defense.",
		effectAccuracy: 100,
	},
	'max flare': {
		name: 'max flare',
		type: 'fire',
		attack: 30,
		accuracy: 80,
		effect: 'fire type dynamax move. summons harsh sunlight.',
		effectAccuracy: 0,
	},
	'max flutterby': {
		name: 'max flutterby',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "bug type dynamax move. lowers the target's special attack.",
		effectAccuracy: 100,
	},
	'max geyser': {
		name: 'max geyser',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: 'water type dynamax move. summons heavy rain.',
		effectAccuracy: 0,
	},
	'max guard': {
		name: 'max guard',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'status category dynamax move. protects the user.',
		effectAccuracy: 0,
	},
	'max hailstorm': {
		name: 'max hailstorm',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'ice type dynamax move. summons hail.',
		effectAccuracy: 0,
	},
	'max knuckle': {
		name: 'max knuckle',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: "fighting type dynamax move. increases the team's attack.",
		effectAccuracy: 100,
	},
	'max lightning': {
		name: 'max lightning',
		type: 'electric',
		attack: 30,
		accuracy: 80,
		effect: 'electric type dynamax move. summons electric terrain.',
		effectAccuracy: 0,
	},
	'max mindstorm': {
		name: 'max mindstorm',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'psychic type dynamax move. summons psychic terrain.',
		effectAccuracy: 0,
	},
	'max ooze': {
		name: 'max ooze',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect: "poison type dynamax move. increases the team's special attack.",
		effectAccuracy: 100,
	},
	'max overgrowth': {
		name: 'max overgrowth',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'grass type dynamax move. summons grassy terrain.',
		effectAccuracy: 0,
	},
	'max phantasm': {
		name: 'max phantasm',
		type: 'ghost',
		attack: 30,
		accuracy: 80,
		effect: "ghost type dynamax move. lowers the target's defense.",
		effectAccuracy: 100,
	},
	'max quake': {
		name: 'max quake',
		type: 'ground',
		attack: 30,
		accuracy: 80,
		effect: "ground type dynamax move. increases the team's special defense.",
		effectAccuracy: 100,
	},
	'max rockfall': {
		name: 'max rockfall',
		type: 'rock',
		attack: 30,
		accuracy: 80,
		effect: 'rock type dynamax move. summons a sandstorm.',
		effectAccuracy: 0,
	},
	'max starfall': {
		name: 'max starfall',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'fairy type dynamax move. summons misty terrain.',
		effectAccuracy: 0,
	},
	'max steelspike': {
		name: 'max steelspike',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: "steel type dynamax move. raises the team's defense.",
		effectAccuracy: 100,
	},
	'max strike': {
		name: 'max strike',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "normal type dynamax move. lowers the target's speed.",
		effectAccuracy: 100,
	},
	'max wyrmwind': {
		name: 'max wyrmwind',
		type: 'dragon',
		attack: 30,
		accuracy: 80,
		effect: "dragon type dynamax move. lowers the target's attack.",
		effectAccuracy: 100,
	},
	'me first': {
		name: 'me first',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user copies the opponent's attack with 1.5× power.",
		effectAccuracy: 0,
	},
	'mean look': {
		name: 'mean look',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'opponent cannot flee or switch.',
		effectAccuracy: 0,
	},
	meditate: {
		name: 'meditate',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack.",
		effectAccuracy: 0,
	},
	memento: {
		name: 'memento',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: "user faints, sharply lowers opponent's attack and special attack.",
		effectAccuracy: 0,
	},
	'metal sound': {
		name: 'metal sound',
		type: 'steel',
		attack: 30,
		accuracy: 85,
		effect: "sharply lowers opponent's special defense.",
		effectAccuracy: 0,
	},
	metronome: {
		name: 'metronome',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user performs almost any move in the game at random.',
		effectAccuracy: 0,
	},
	'milk drink': {
		name: 'milk drink',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers half its max hp.',
		effectAccuracy: 0,
	},
	mimic: {
		name: 'mimic',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "copies the opponent's last move.",
		effectAccuracy: 0,
	},
	'mind reader': {
		name: 'mind reader',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user's next attack is guaranteed to hit.",
		effectAccuracy: 0,
	},
	minimize: {
		name: 'minimize',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's evasiveness.",
		effectAccuracy: 0,
	},
	'miracle eye': {
		name: 'miracle eye',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "resets opponent's evasiveness, removes dark's psychic immunity.",
		effectAccuracy: 0,
	},
	'mirror coat': {
		name: 'mirror coat',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: 'when hit by a special attack, user strikes back with 2x power.',
		effectAccuracy: 0,
	},
	'mirror move': {
		name: 'mirror move',
		type: 'flying',
		attack: 30,
		accuracy: 80,
		effect: "user performs the opponent's last move.",
		effectAccuracy: 0,
	},
	mist: {
		name: 'mist',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: "user's stats cannot be changed for a period of time.",
		effectAccuracy: 0,
	},
	'misty terrain': {
		name: 'misty terrain',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'protects the field from status conditions for 5 turns.',
		effectAccuracy: 0,
	},
	moonlight: {
		name: 'moonlight',
		type: 'fairy',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers hp. amount varies with the weather.',
		effectAccuracy: 0,
	},
	'morning sun': {
		name: 'morning sun',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers hp. amount varies with the weather.',
		effectAccuracy: 0,
	},
	'mud sport': {
		name: 'mud sport',
		type: 'ground',
		attack: 30,
		accuracy: 80,
		effect: 'weakens the power of electric-type moves.',
		effectAccuracy: 0,
	},
	'nasty plot': {
		name: 'nasty plot',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's special attack.",
		effectAccuracy: 0,
	},
	'natural gift': {
		name: 'natural gift',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "power and type depend on the user's held berry.",
		effectAccuracy: 0,
	},
	'nature power': {
		name: 'nature power',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'uses a certain move based on the current terrain.',
		effectAccuracy: 0,
	},
	nightmare: {
		name: 'nightmare',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: 'the sleeping opponent loses 25% of its max hp each turn.',
		effectAccuracy: 0,
	},
	'no retreat': {
		name: 'no retreat',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: 'raises all stats but user cannot switch out.',
		effectAccuracy: 100,
	},
	'noble roar': {
		name: 'noble roar',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's attack and special attack.",
		effectAccuracy: 100,
	},
	obstruct: {
		name: 'obstruct',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'protects the user and sharply lowers defence on contact.',
		effectAccuracy: 0,
	},
	octolock: {
		name: 'octolock',
		type: 'fighting',
		attack: 30,
		accuracy: 100,
		effect:
			"lowers opponent's defense and special defense every turn, and they cannot flee or switch out.",
		effectAccuracy: 0,
	},
	'odor sleuth': {
		name: 'odor sleuth',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			"resets opponent's evasiveness, and allows normal- and fighting-type attacks to hit ghosts.",
		effectAccuracy: 0,
	},
	'pain split': {
		name: 'pain split',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "the user's and opponent's hp becomes the average of both.",
		effectAccuracy: 0,
	},
	'parting shot': {
		name: 'parting shot',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's attack and special attack then switches out.",
		effectAccuracy: 100,
	},
	'perish song': {
		name: 'perish song',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'any pokémon in play when this attack is used faints in 3 turns.',
		effectAccuracy: 0,
	},
	'play nice': {
		name: 'play nice',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "lowers opponent's attack. always hits.",
		effectAccuracy: 100,
	},
	powder: {
		name: 'powder',
		type: 'bug',
		attack: 30,
		accuracy: 100,
		effect: 'damages pokémon using fire type moves.',
		effectAccuracy: 0,
	},
	'power shift': {
		name: 'power shift',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'switches attack and defense stats.',
		effectAccuracy: 0,
	},
	'power split': {
		name: 'power split',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'averages attack and special attack with the target.',
		effectAccuracy: 0,
	},
	'power swap': {
		name: 'power swap',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'user and opponent swap attack and special attack.',
		effectAccuracy: 0,
	},
	'power trick': {
		name: 'power trick',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "user's own attack and defense switch.",
		effectAccuracy: 0,
	},
	'power trip': {
		name: 'power trip',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect:
			"the user boasts its strength and attacks the target. the more the user's stats are raised, the greater the move's power.",
		effectAccuracy: 0,
	},
	present: {
		name: 'present',
		type: 'normal',
		attack: 30,
		accuracy: 90,
		effect: 'either deals damage or heals.',
		effectAccuracy: 0,
	},
	protect: {
		name: 'protect',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'protects the user, but may fail if used consecutively.',
		effectAccuracy: 0,
	},
	'psych up': {
		name: 'psych up',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "copies the opponent's stat changes.",
		effectAccuracy: 0,
	},
	'psychic terrain': {
		name: 'psychic terrain',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'prevents priority moves from being used for 5 turns.',
		effectAccuracy: 0,
	},
	'psycho shift': {
		name: 'psycho shift',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: "transfers user's status condition to the opponent.",
		effectAccuracy: 0,
	},
	punishment: {
		name: 'punishment',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: "power increases when opponent's stats have been raised.",
		effectAccuracy: 0,
	},
	purify: {
		name: 'purify',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect:
			"the user heals the target's status condition. if the move succeeds, it also restores the user's own hp.",
		effectAccuracy: 0,
	},
	quash: {
		name: 'quash',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'makes the target act last this turn.',
		effectAccuracy: 0,
	},
	'quick guard': {
		name: 'quick guard',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: "protects the user's team from high-priority moves.",
		effectAccuracy: 0,
	},
	'quiver dance': {
		name: 'quiver dance',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "raises user's special attack, special defense and speed.",
		effectAccuracy: 0,
	},
	'rage powder': {
		name: 'rage powder',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: 'forces attacks to hit user, not team-mates.',
		effectAccuracy: 0,
	},
	'rain dance': {
		name: 'rain dance',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: 'makes it rain for 5 turns.',
		effectAccuracy: 0,
	},
	recover: {
		name: 'recover',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers half its max hp.',
		effectAccuracy: 0,
	},
	recycle: {
		name: 'recycle',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user's used hold item is restored.",
		effectAccuracy: 0,
	},
	reflect: {
		name: 'reflect',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'halves damage from physical attacks for 5 turns.',
		effectAccuracy: 0,
	},
	'reflect type': {
		name: 'reflect type',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "user becomes the target's type.",
		effectAccuracy: 0,
	},
	refresh: {
		name: 'refresh',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'cures paralysis, poison, and burns.',
		effectAccuracy: 0,
	},
	rest: {
		name: 'rest',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'user sleeps for 2 turns, but user is fully healed.',
		effectAccuracy: 0,
	},
	return: {
		name: 'return',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'power increases with higher friendship.',
		effectAccuracy: 0,
	},
	reversal: {
		name: 'reversal',
		type: 'fighting',
		attack: 30,
		accuracy: 100,
		effect: "the lower the user's hp, the higher the power.",
		effectAccuracy: 0,
	},
	'revival blessing': {
		name: 'revival blessing',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'revives a fainted party pokémon to half hp.',
		effectAccuracy: 0,
	},
	roar: {
		name: 'roar',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'in battles, the opponent switches. in the wild, the pokémon runs.',
		effectAccuracy: 0,
	},
	'rock polish': {
		name: 'rock polish',
		type: 'rock',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's speed.",
		effectAccuracy: 0,
	},
	'role play': {
		name: 'role play',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "user copies the opponent's ability.",
		effectAccuracy: 0,
	},
	roost: {
		name: 'roost',
		type: 'flying',
		attack: 30,
		accuracy: 80,
		effect:
			'user recovers half of its max hp and loses the flying type temporarily.',
		effectAccuracy: 0,
	},
	rototiller: {
		name: 'rototiller',
		type: 'ground',
		attack: 30,
		accuracy: 80,
		effect: 'raises attack and special attack of grass-types.',
		effectAccuracy: 100,
	},
	safeguard: {
		name: 'safeguard',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "the user's party is protected from status conditions.",
		effectAccuracy: 0,
	},
	'sand attack': {
		name: 'sand attack',
		type: 'ground',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's accuracy.",
		effectAccuracy: 0,
	},
	sandstorm: {
		name: 'sandstorm',
		type: 'rock',
		attack: 30,
		accuracy: 80,
		effect: 'creates a sandstorm for 5 turns.',
		effectAccuracy: 0,
	},
	'scary face': {
		name: 'scary face',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "sharply lowers opponent's speed.",
		effectAccuracy: 0,
	},
	screech: {
		name: 'screech',
		type: 'normal',
		attack: 30,
		accuracy: 85,
		effect: "sharply lowers opponent's defense.",
		effectAccuracy: 0,
	},
	'self-destruct': {
		name: 'self-destruct',
		type: 'normal',
		attack: 200,
		accuracy: 100,
		effect: 'user faints.',
		effectAccuracy: 0,
	},
	sharpen: {
		name: 'sharpen',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack.",
		effectAccuracy: 0,
	},
	'shed tail': {
		name: 'shed tail',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'creates a substitute, then swaps places with a party pokémon in waiting.',
		effectAccuracy: 0,
	},
	'shell smash': {
		name: 'shell smash',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			"sharply raises user's attack, special attack and speed but lowers defense and special defense.",
		effectAccuracy: 0,
	},
	shelter: {
		name: 'shelter',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense.",
		effectAccuracy: 0,
	},
	'shift gear': {
		name: 'shift gear',
		type: 'steel',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and sharply raises speed.",
		effectAccuracy: 0,
	},
	'shore up': {
		name: 'shore up',
		type: 'ground',
		attack: 30,
		accuracy: 80,
		effect:
			'the user regains up to half of its max hp. it restores more hp in a sandstorm.',
		effectAccuracy: 0,
	},
	'silk trap': {
		name: 'silk trap',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "protects the user and lowers opponent's speed on contact.",
		effectAccuracy: 0,
	},
	'simple beam': {
		name: 'simple beam',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "changes target's ability to simple.",
		effectAccuracy: 0,
	},
	'skill swap': {
		name: 'skill swap',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'the user swaps abilities with the opponent.',
		effectAccuracy: 0,
	},
	'slack off': {
		name: 'slack off',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers half its max hp.',
		effectAccuracy: 0,
	},
	'sleep talk': {
		name: 'sleep talk',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user performs one of its own moves while sleeping.',
		effectAccuracy: 0,
	},
	'smelling salts': {
		name: 'smelling salts',
		type: 'normal',
		attack: 70,
		accuracy: 100,
		effect: 'power doubles if opponent is paralyzed, but cures it.',
		effectAccuracy: 0,
	},
	smokescreen: {
		name: 'smokescreen',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's accuracy.",
		effectAccuracy: 0,
	},
	snatch: {
		name: 'snatch',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: "steals the effects of the opponent's next move.",
		effectAccuracy: 0,
	},
	snowscape: {
		name: 'snowscape',
		type: 'ice',
		attack: 30,
		accuracy: 80,
		effect: 'raises defense of ice types for 5 turns.',
		effectAccuracy: 0,
	},
	soak: {
		name: 'soak',
		type: 'water',
		attack: 30,
		accuracy: 100,
		effect: "changes the target's type to water.",
		effectAccuracy: 0,
	},
	'soft-boiled': {
		name: 'soft-boiled',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers half its max hp.',
		effectAccuracy: 0,
	},
	'sparkling aria': {
		name: 'sparkling aria',
		type: 'water',
		attack: 90,
		accuracy: 100,
		effect: 'heals the burns of its target.',
		effectAccuracy: 0,
	},
	'speed swap': {
		name: 'speed swap',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'the user exchanges speed stats with the target.',
		effectAccuracy: 0,
	},
	'spicy extract': {
		name: 'spicy extract',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect:
			"harshly lowers the opponent's defense and sharply raises their attack.",
		effectAccuracy: 0,
	},
	'spider web': {
		name: 'spider web',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: 'opponent cannot escape/switch.',
		effectAccuracy: 0,
	},
	spikes: {
		name: 'spikes',
		type: 'ground',
		attack: 30,
		accuracy: 80,
		effect: 'damages opponents switching into battle.',
		effectAccuracy: 0,
	},
	'spiky shield': {
		name: 'spiky shield',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'protects the user and inflicts damage on contact.',
		effectAccuracy: 0,
	},
	'spit up': {
		name: 'spit up',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'power depends on how many times the user performed stockpile.',
		effectAccuracy: 0,
	},
	spite: {
		name: 'spite',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: "the opponent's last move loses 2-5 pp.",
		effectAccuracy: 0,
	},
	spotlight: {
		name: 'spotlight',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'the user shines a spotlight on the target so that only the target will be attacked during the turn.',
		effectAccuracy: 0,
	},
	'stealth rock': {
		name: 'stealth rock',
		type: 'rock',
		attack: 30,
		accuracy: 80,
		effect: 'damages opponents switching into battle.',
		effectAccuracy: 0,
	},
	'sticky web': {
		name: 'sticky web',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "lowers opponent's speed when switching into battle.",
		effectAccuracy: 0,
	},
	stockpile: {
		name: 'stockpile',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'stores energy for use with spit up and swallow.',
		effectAccuracy: 0,
	},
	'strange steam': {
		name: 'strange steam',
		type: 'fairy',
		attack: 90,
		accuracy: 95,
		effect: 'may confuse opponent.',
		effectAccuracy: 20,
	},
	'strength sap': {
		name: 'strength sap',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect:
			"the user restores its hp by the same amount as the target's attack stat. it also lowers the target's attack stat.",
		effectAccuracy: 100,
	},
	'string shot': {
		name: 'string shot',
		type: 'bug',
		attack: 30,
		accuracy: 95,
		effect: "sharply lowers opponent's speed.",
		effectAccuracy: 0,
	},
	'struggle bug': {
		name: 'struggle bug',
		type: 'bug',
		attack: 50,
		accuracy: 100,
		effect: "lowers opponent's special attack.",
		effectAccuracy: 100,
	},
	'stuff cheeks': {
		name: 'stuff cheeks',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'the user eats its held berry, then sharply raises its defense stat.',
		effectAccuracy: 100,
	},
	substitute: {
		name: 'substitute',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'uses hp to creates a decoy that takes hits.',
		effectAccuracy: 0,
	},
	'sunny day': {
		name: 'sunny day',
		type: 'fire',
		attack: 30,
		accuracy: 80,
		effect: 'makes it sunny for 5 turns.',
		effectAccuracy: 0,
	},
	swagger: {
		name: 'swagger',
		type: 'normal',
		attack: 30,
		accuracy: 85,
		effect: 'confuses opponent, but sharply raises its attack.',
		effectAccuracy: 0,
	},
	swallow: {
		name: 'swallow',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'the more times the user has performed stockpile, the more hp is recovered.',
		effectAccuracy: 0,
	},
	'sweet scent': {
		name: 'sweet scent',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's evasiveness.",
		effectAccuracy: 0,
	},
	switcheroo: {
		name: 'switcheroo',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'swaps held items with the opponent.',
		effectAccuracy: 0,
	},
	'swords dance': {
		name: 'swords dance',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "sharply raises user's attack.",
		effectAccuracy: 0,
	},
	synthesis: {
		name: 'synthesis',
		type: 'grass',
		attack: 30,
		accuracy: 80,
		effect: 'user recovers hp. amount varies with the weather.',
		effectAccuracy: 0,
	},
	'tail glow': {
		name: 'tail glow',
		type: 'bug',
		attack: 30,
		accuracy: 80,
		effect: "drastically raises user's special attack.",
		effectAccuracy: 0,
	},
	'tail whip': {
		name: 'tail whip',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's defense.",
		effectAccuracy: 0,
	},
	tailwind: {
		name: 'tailwind',
		type: 'flying',
		attack: 30,
		accuracy: 80,
		effect: 'doubles speed for 4 turns.',
		effectAccuracy: 0,
	},
	'take heart': {
		name: 'take heart',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "heals user's status conditions and raises its stats.",
		effectAccuracy: 0,
	},
	'tar shot': {
		name: 'tar shot',
		type: 'rock',
		attack: 30,
		accuracy: 100,
		effect:
			"lowers the opponent's speed and makes them weaker to fire-type moves.",
		effectAccuracy: 100,
	},
	taunt: {
		name: 'taunt',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'opponent can only use moves that attack.',
		effectAccuracy: 0,
	},
	'tearful look': {
		name: 'tearful look',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			"the user gets teary eyed to make the target lose its combative spirit. this lowers the target's attack and sp. atk stats.",
		effectAccuracy: 100,
	},
	teatime: {
		name: 'teatime',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'forces all pokémon on the field to eat their berries.',
		effectAccuracy: 0,
	},
	'teeter dance': {
		name: 'teeter dance',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'confuses all pokémon.',
		effectAccuracy: 0,
	},
	telekinesis: {
		name: 'telekinesis',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect:
			"ignores opponent's evasiveness for three turns, add ground immunity.",
		effectAccuracy: 0,
	},
	teleport: {
		name: 'teleport',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect:
			'allows user to flee wild battles; also warps player to last pokécenter.',
		effectAccuracy: 0,
	},
	'terrain pulse': {
		name: 'terrain pulse',
		type: 'normal',
		attack: 50,
		accuracy: 100,
		effect: 'type and power change depending on the terrain in effect.',
		effectAccuracy: 0,
	},
	'thousand arrows': {
		name: 'thousand arrows',
		type: 'ground',
		attack: 90,
		accuracy: 100,
		effect: 'makes flying-type pokémon vulnerable to ground moves.',
		effectAccuracy: 0,
	},
	'thousand waves': {
		name: 'thousand waves',
		type: 'ground',
		attack: 90,
		accuracy: 100,
		effect: 'opponent cannot flee or switch.',
		effectAccuracy: 0,
	},
	'thunder cage': {
		name: 'thunder cage',
		type: 'electric',
		attack: 80,
		accuracy: 90,
		effect: 'deals damage and traps opponent, damaging them for 4-5 turns.',
		effectAccuracy: 0,
	},
	tickle: {
		name: 'tickle',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "lowers opponent's attack and defense.",
		effectAccuracy: 0,
	},
	'tidy up': {
		name: 'tidy up',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect:
			'removes the effects of entry hazards and substitute, and boosts user’s attack and speed.',
		effectAccuracy: 0,
	},
	'topsy-turvy': {
		name: 'topsy-turvy',
		type: 'dark',
		attack: 30,
		accuracy: 80,
		effect: 'reverses stat changes of opponent.',
		effectAccuracy: 0,
	},
	torment: {
		name: 'torment',
		type: 'dark',
		attack: 30,
		accuracy: 100,
		effect: 'opponent cannot use the same move in a row.',
		effectAccuracy: 0,
	},
	'toxic spikes': {
		name: 'toxic spikes',
		type: 'poison',
		attack: 30,
		accuracy: 80,
		effect: 'poisons opponents switching into battle.',
		effectAccuracy: 0,
	},
	trailblaze: {
		name: 'trailblaze',
		type: 'grass',
		attack: 50,
		accuracy: 100,
		effect: "raises user's speed.",
		effectAccuracy: 0,
	},
	transform: {
		name: 'transform',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'user takes on the form and attacks of the opponent.',
		effectAccuracy: 0,
	},
	trick: {
		name: 'trick',
		type: 'psychic',
		attack: 30,
		accuracy: 100,
		effect: 'swaps held items with the opponent.',
		effectAccuracy: 0,
	},
	'trick room': {
		name: 'trick room',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: 'slower pokémon move first in the turn for 5 turns.',
		effectAccuracy: 0,
	},
	'trick-or-treat': {
		name: 'trick-or-treat',
		type: 'ghost',
		attack: 30,
		accuracy: 100,
		effect: 'adds ghost type to opponent.',
		effectAccuracy: 0,
	},
	'trump card': {
		name: 'trump card',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: 'the lower the pp, the higher the power.',
		effectAccuracy: 0,
	},
	'u-turn': {
		name: 'u-turn',
		type: 'bug',
		attack: 70,
		accuracy: 100,
		effect: 'user switches out immediately after attacking.',
		effectAccuracy: 0,
	},
	'venom drench': {
		name: 'venom drench',
		type: 'poison',
		attack: 30,
		accuracy: 100,
		effect: "lowers poisoned opponent's special attack and speed.",
		effectAccuracy: 100,
	},
	'victory dance': {
		name: 'victory dance',
		type: 'fighting',
		attack: 30,
		accuracy: 80,
		effect: 'raises attack and defense.',
		effectAccuracy: 0,
	},
	'vise grip': {
		name: 'vise grip',
		type: 'normal',
		attack: 55,
		accuracy: 100,
		effect: '',
		effectAccuracy: 0,
	},
	'volt switch': {
		name: 'volt switch',
		type: 'electric',
		attack: 70,
		accuracy: 100,
		effect: 'user must switch out after attacking.',
		effectAccuracy: 0,
	},
	'water pledge': {
		name: 'water pledge',
		type: 'water',
		attack: 80,
		accuracy: 100,
		effect:
			'added effects appear if preceded by fire pledge or succeeded by grass pledge.',
		effectAccuracy: 0,
	},
	'water sport': {
		name: 'water sport',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: 'weakens the power of fire-type moves.',
		effectAccuracy: 0,
	},
	whirlwind: {
		name: 'whirlwind',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'in battles, the opponent switches. in the wild, the pokémon runs.',
		effectAccuracy: 0,
	},
	'wide guard': {
		name: 'wide guard',
		type: 'rock',
		attack: 30,
		accuracy: 80,
		effect: "protects the user's team from multi-target attacks.",
		effectAccuracy: 0,
	},
	wish: {
		name: 'wish',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: 'the user recovers hp in the following turn.',
		effectAccuracy: 0,
	},
	withdraw: {
		name: 'withdraw',
		type: 'water',
		attack: 30,
		accuracy: 80,
		effect: "raises user's defense.",
		effectAccuracy: 0,
	},
	'wonder room': {
		name: 'wonder room',
		type: 'psychic',
		attack: 30,
		accuracy: 80,
		effect: "swaps every pokémon's defense and special defense for 5 turns.",
		effectAccuracy: 0,
	},
	'work up': {
		name: 'work up',
		type: 'normal',
		attack: 30,
		accuracy: 80,
		effect: "raises user's attack and special attack.",
		effectAccuracy: 0,
	},
	'worry seed': {
		name: 'worry seed',
		type: 'grass',
		attack: 30,
		accuracy: 100,
		effect: "changes the opponent's ability to insomnia.",
		effectAccuracy: 0,
	},
	'wring out': {
		name: 'wring out',
		type: 'normal',
		attack: 30,
		accuracy: 100,
		effect: "the higher the opponent's hp, the higher the damage.",
		effectAccuracy: 0,
	},
};

export default moveDetails;
