import { Button } from '@mui/material';
import { css } from '../css_constants';
import PokemonItem from './Pokemon';

export function PokemonSelect(props) {
	props?.state?.myState?.sort((a, b) => {
		return a.team_order - b.team_order;
	});
	return (
		<div className="grid grid-cols-2 text-center mt-6 gap-5 text-blue-100">
			<div className={`col-span-2 text-lg font-bold ${css.textLightColor}`}>
				Battle
			</div>
			<div className="flex flex-col">
				<p className={`${css.textLightColor} font-semibold text-base`}>
					Opponent{' '}
				</p>
				{props.state?.opponent
					? props.state.opponent.map((op, index) => {
							return (
								<PokemonItem
									disabled={op.hp <= 0}
									index={index}
									pokemon={op}
									onSelect={props.onSelect}
									select={props.select}
									isOpponent={true}
								/>
							);
					  })
					: ''}
			</div>
			<div className="flex flex-col">
				<p className={`${css.textLightColor} font-semibold text-base`}>
					Your team{' '}
				</p>
				{props.state?.myState
					? props.state.myState.map((op, index) => {
							return (
								<PokemonItem
									disabled={op.hp <= 0}
									index={index}
									pokemon={op}
									onSelect={props.onSelect}
									select={props.select}
								/>
							);
					  })
					: ''}
			</div>
			<div className="col-span-2 place-self-center">
				<Button
					variant="contained"
					onClick={props?.onClick}
					size="small"
					className={`${css.buttonRedStyle}`}
				>
					Select this pokemon
				</Button>
			</div>
		</div>
	);
}
